<template>
    <mdb-container class="flex flex-column px-0 my-2 align-items-center">
        <div class="collapsable-header mx-auto">
            <div class="header-title-container header-active-typhograpy">
                <span class="text-uppercase">
                    {{ getTicketsAmount === 0 ? $t('default.RESERVATION_STEP_3') : getTicketsAmount }}
                </span>
                <!-- <span style="width: 30px; height: 30px; background-color: inherit;" role="button"
                    @click="() => { ticketsVisiblesManuelmente = !(ticketsVisiblesManuelmente) }"
                    v-bind:class="{ 'd-none': getTicketsAmount === 0 }">
                    <font-awesome-icon class="" icon="fa-pen" />
                </span> -->
            </div>

            <div style="width: 100%; margin-top: 0;" class="animated fadeIn"
                v-bind:class="{ 'd-none': !ticketsVisible }">
                <hr style="color: black; margin-top: 0" />
                <div style="text-align: start !important;">

                    <template>
                        <mdb-row v-for="producto in grupoDeProductos" :key="producto._id">
                            <mdb-col col="12">

                                <h6 class="text-color-spainhn">{{ producto.nombre }}</h6>
                                <div class="w-100 spainhn-text pt-2 pb-2 px-2">
                                    <strong>{{ $t('default.RESERVATION_STEP_3_1') }}</strong>
                                </div>

                            </mdb-col>

                            <mdb-col col="12">
                                <template v-if="grupoDePrecios[producto._id]">
                                    
                                    <mdb-container class="border-bottom py-2 border-top"
                                    v-for="(precio, index) in grupoDePrecios[producto._id]" v-bind:key="index">
                                        <div class="w-100 pe-3 text-wrap text-start spainhn-small-text"
                                            v-bind:class="{ 'd-none': !precio.mensajeDeComision }">
                                            <small>
                                                {{ $t("default.BOOKINGS.MENSAJE_COMISION_DE_GESTION", {
                                                    msg:
                                                precio.mensajeDeComision}) }}
                                            </small>
                                        </div>
                                        <mdb-row class="justify-content-between">
                                            <mdb-col col="12" md="6">
                                                <div class="d-flex flex-column mx-auto text-center">
                                                    <strong class="spainhn-text">
                                                        {{ precio.descripciones[idiomaIndex]?.texto }}
                                                        <span v-if="precio.helpers?.length">
                                                            <template v-if="precio.helpers.filter(h => h.idioma ===
                                                                        selectedIdioma)[0]?.texto">
                                                                <mdb-tooltip material trigger="hover">
                                                                    <span slot="tip">
                                                                        {{ precio.helpers.filter(h => h.idioma ===
                                                                        selectedIdioma)[0]?.texto }}
                                                                    </span>
                                                                    <font-awesome-icon slot="reference"
                                                                        icon="question-circle" />
                                                                </mdb-tooltip>
                                                            </template>
                                                        </span>
                                                    </strong>
                                                    <small class="spainhn-text">
                                                        {{ precio.pvp.toLocaleString('es-ES', {
                                                            style: 'currency',
                                                        'currency': 'EUR' }) }}
                                                    </small>
                                                </div>
                                            </mdb-col>
                                            <mdb-col col="12" md="6">
                                                <booking-buttons ref="botoneras"
                                                    :min-personas="precio?.minPersonas" 
                                                    :precioId="precio._id" 
                                                    :tarifaId="precio.tarifaId"
                                                    :pvpUnitario="precio.pvp"
                                                    :productoId="producto._id"
                                                    :precioBase="precio.precio"
                                                    :nombre="getTextInLanguage(precio.descripciones)"
                                                    :vinculaciones="precio.preciosVinculados"
                                                    @change="(evt) => preciosEdit(evt, producto._id)" :maximo-incremento="-1" />
                                            </mdb-col>
                                        </mdb-row>
                                    </mdb-container>

                                </template>
                            </mdb-col>
                        </mdb-row>
                        <div class="clearfix">
                            <mdb-btn color="indigo"
                                class="spainhn-button mt-3 mb-2 ms-auto float-right" size="lg" @click="editarResumen"
                                :disabled="deshabilitarAgregar">
                                <strong>{{$t('default.SPAINHN_BUTTON_ADD_CART') }}</strong>
                                <br>
                                <!-- <strong>{{ resumenTotal }}</strong> -->
                            </mdb-btn>
                        </div>
                    </template>

                    <!-- <div v-bind:class="{ 'd-none': !precios.length }" id="precios-disponibles">

                        <div class="clearfix">
                            <mdb-btn color="indigo" v-bind:class="{ 'd-none': !precios.length }"
                                class="spainhn-button mt-3 mb-2 ms-auto float-right" size="lg" @click="editarResumen"
                                :disabled="productoAgregado"><strong>{{
                                    $t('default.SPAINHN_BUTTON_ADD_CART') }}</strong></mdb-btn>
                        </div>

                    </div>

                    <div v-bind:class="{ 'd-none': precios.length }" class="mt-2 border px-3 py-4 border-warning mb-3"
                        style="background-color:#fcf8e3">
                        <div class="row">
                            <div class="col-md-2 col-12 my-auto">
                                <div class="d-flex">
                                    <font-awesome-icon class="my-auto text-warning mx-auto" icon="fa-circle-exclamation"
                                        size="3x"></font-awesome-icon>
                                </div>
                            </div>
                            <div class="col-10 mx-auto">
                                <p><strong class="text-spainhn my-auto">
                                        {{ $t("default.MENSAJE_SELECCIONAR_HORARIO") }}
                                    </strong></p>
                            </div>
                        </div>
                    </div> -->

                </div>
            </div>

        </div>
    </mdb-container>
</template>

<script>
import { mdbContainer, mdbBtn, mdbRow, mdbCol } from 'mdbvue';
import BookingButtons from './BookingButtonsImprove.vue';
export default {
    components: {
        mdbContainer,
        mdbBtn, 
        mdbRow, mdbCol,
        BookingButtons
    },
    props: {
        tipoCobro: Boolean,
        comision: Number,
        simbolo: String,
        productos: { type: Array, default: () => [] },
        precios: { type: Object, default: () => { } },
        permitirSeleccionDePrecios: { type: Boolean, default: false },
        totalDeLaVenta: {type: Number, default: 0},
        bloquearBotones: { type: Boolean, default: false },
        seleccionDiferida: { type: Boolean, default: true },
        preciosEnPaquete: { type: Array, default: () => [] },
        paqueteData: { type: Object, default: () => {} },
    },
    data() {
        return {
            ticketsVisiblesManuelmente: false,
            selecciones: new Map(),
            selected: [],
            debouncing: false
        }
    },
    methods: {
        getTextInLanguage( descriptions ){
            const targetLanguage = this.$store.getters.getIdioma ?? "es";

            if( descriptions ){
                const targetText = descriptions.find( d => d.idioma === targetLanguage )?.texto;
    
                if( targetText ){
                    return targetText
                }
                const defaultText = descriptions[0]?.texto;
                return defaultText;
            }
            else{
                return "";
            }
        },
        editarResumen() {
            if( this.selecciones.size < this.productos.length && this.seleccionDiferida ){
                this.$toast.warning(this.$t("default.NOTIFICATIONS.HELP_PRODUCT_NUMBER"));
                return;
            }
            const cantidadesDeTickets = new Set();

            this.selecciones.forEach( p => {
                const totalDeTickets = Object.values(p).map( d => d.cantidad ).reduce( ( acc, cantidad ) => acc + cantidad, 0 );

                cantidadesDeTickets.add( totalDeTickets );
            })

            if( cantidadesDeTickets.size > 1 ){
                this.$toast.warning(this.$t("default.NOTIFICATIONS.HELP_PRODUCT_NUMBER"));
            }else if( cantidadesDeTickets.size === 1 ){
                this.debouncing = true;
                this.$emit( 'submitCart', this.selecciones );
                setTimeout(() => {
                    this.debouncing = false;
                }, 3000);
            }else{
                this.$toast.error(this.$t("default.NOTIFICATIONS.INCORRECT_DATA"))
            }
        },
        preciosEdit( evt, productoId){
            const totalDeBoletosSeleccionados = this.$store.getters.boletosAgregados;

            if( totalDeBoletosSeleccionados === 0 ){
                this.selecciones.clear();
            }

            if( !this.selecciones.has( productoId ) ){
                // const infoDePrecios = new Map();
                const infoDePrecios = { [evt.precioId]: {} };
                this.selecciones.set( productoId, infoDePrecios )
            }

            const tempSelect = this.selecciones.get( productoId );
            // Verificar primero que exista, antes de hacer set
            if( !tempSelect[evt.precioId] ){
                tempSelect[evt.precioId] = {};
            }
            tempSelect[evt.precioId] = {
                cantidad: evt.cantidad, 
                pvpUnitario: evt.pvpUnitario, 
                precio: evt.precioBase,
                nombre: evt.nombre,
                estadisticas: evt.vinculaciones?.map( pv => {
                    const obj = {...pv, cantidad: pv.cantidad };
                    return obj;
                }) ?? []
            }
            // this.selecciones.get( productoId ).set( evt.precioId, { 
            //     cantidad: evt.cantidad, 
            //     pvpUnitario: evt.pvpUnitario, 
            //     precio: evt.precioBase,
            //     nombre: evt.nombre,
            //     estadisticas: evt.vinculaciones?.map( pv => {
            //         const obj = {...pv, cantidad: pv.cantidad };
            //         return obj;
            //     }) ?? []
            // })

            this.selecciones.set( productoId, tempSelect );
            const totalDeBoletosEnElProducto = Object.values(this.selecciones.get( productoId )).map( d => d.cantidad ).reduce((acc, cantidad) => acc + cantidad, 0);

            let productoConMasNumeroDeTickets = 0;
            let pvpTotal = 0;
            this.selecciones.forEach( p => {
                const totalDeTickets = Object.values(p).map( d => d.cantidad ).reduce( ( acc, cantidad ) => acc + cantidad, 0 );
                const subtotal =  Object.values(p).map( d => ( Number((d.cantidad * d.pvpUnitario).toFixed(2)) ) ).reduce( ( acc, pvp ) => Number((acc + pvp).toFixed(2)), 0 ) ?? 0;
                pvpTotal += subtotal;

                if( productoConMasNumeroDeTickets <= totalDeTickets ){
                    productoConMasNumeroDeTickets = totalDeTickets;
                }
            })

            this.$store.commit( 'actualizarNumeroDeBoletos', 
                { 
                    total: productoConMasNumeroDeTickets,
                    productoId,
                    totalDeBoletosEnElProducto
                } 
            );
            this.selected = [totalDeBoletosEnElProducto];

            this.$store.commit( 'actualizarTotalDeLaReserva', pvpTotal );
        }
    },
    computed: {
        grupoDeProductos(){
            if( this.seleccionDiferida ){
                return this.productos;
            }else{
                if( this.paqueteData?._id){
                    return [ { nombre: this.paqueteData.titulo, _id: this.paqueteData._id} ];
                }else{
                    return this.productos;
                }
            }
        },
        grupoDePrecios(){
            if( this.seleccionDiferida ){
                return this.precios;
            }else{
                if( this.paqueteData._id ){
                    return ({
                        [ this.paqueteData._id]: this.preciosEnPaquete.map( p => {
                            const precio = {...p, pvp: p.precio};

                            return precio;
                        })
                    })
                }else{
                    return this.precios;
                }
            }
        },
        getTicketsAmount() {
            return this.$store.getters.boletosAgregados ?? 0;
        },
        ticketsVisible() {

            if (!this.permitirSeleccionDePrecios) {
                return false;
            } else {
                return true;
            }
        },
        selectedIdioma() {
            return 'es'
        },
        idiomaIndex() {
            return 0
        },
        deshabilitarAgregar(){
            if( this.debouncing ){
                return true;
            }else if( !this.permitirSeleccionDePrecios || this.bloquearBotones ){
                return false;
            }else{
                return !(this.$store.getters.boletosAgregados > 0)
            }
            // return false;
        },
        resumenTotal(){
            return this.$store.getters.totalDeLaReserva.toLocaleString( 'es-ES', { style: 'currency', currency: 'EUR' } );
            // if( this.totalDeLaVenta !== null || this.totalDeLaVenta !== undefined ){
            //     return this.totalDeLaVenta.toLocaleString( 'es-ES', { style: 'currency', currency: 'EUR'} );
            // }else{
            //     return (0).toLocaleString( 'es-ES', { style: 'currency', currency: 'EUR'});
            // }
        }
    },
    mounted() {
        this.debouncing = false;
    },
}
</script>

<style scoped>
.collapsable-header {
    border-radius: 10px;
    background: #D9D9D9;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    /* max-width: 520px; */
}

.header-title-container {
    display: flex;
    height: 55px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.header-typhograpy {
    color: #FFF;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.header-active-typhograpy {
    color: var(--spainhn-buy-button);
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}
</style>