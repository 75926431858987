<template>
    <mdb-container class="flex flex-column px-0 my-2">
        <div class="collapsable-header mx-auto">
            <!-- Dejamos un solo color el header sin importar si esta activo o no, ya que no aporta mucho -->
            <!-- <div class="header-title-container" v-bind:class="{'header-active-typhograpy':(visibleHours || horarioSelected !== null), 'header-typhograpy': (!visibleHours && horarioSelected === null) }"> -->
            <div class="header-title-container header-active-typhograpy">
                <span class="text-uppercase">
                    {{ detallesDeLaSeleccion }}
                </span>

                <mdb-btn floating style="" class="red darken-4" role="button"
                    color="red"
                    @click="() => mostrarOpcionesManual = !mostrarOpcionesManual"
                    v-bind:class="{ 'd-none': !horarioSelected }">
                    <font-awesome-icon class="" icon="fa-pen" />
                </mdb-btn>

                <slot name="detalles">
                </slot>

            </div>
            <div style="width: 100%; margin-top: 0;" class="animated fadeIn"
                v-bind:class="{ 'd-none': !mostrarOpciones }">

                <hr style="color: black; margin-top: 0" />

                <div style="text-align: start !important;">

                    <div class="d-flex flex-wrap">
                        <template v-if="opcionesDeHorarios.length">
                            
                            <div class="d-flex py-2 my-2 mx-md-2 mx-auto" v-for="(horario, index) in opcionesDeHorarios"
                                v-bind:key="index"
                                v-bind:class="{ 'd-none': horario.hora === null  }">

                                <div class="text-start py-2 px-4">

                                    <mdb-btn size="lg" @click="setTarifa(horario)"
                                        class="spainhn-button my-auto"
                                        v-bind:class="{ 'fa-bounce': horarioSelected === null, 'active-item-option': horario._id === horarioSelected, 'item-option': horario._id !== horarioSelected }"
                                        :disabled="(horario.pausado === true || horario._id === horarioSelected)">
                                        <font-awesome-icon icon="fa-clock" size="1x" v-bind:class="{ 'fa-shake': horarioSelected === null }" />
                                        {{ horario.label }}
                                    </mdb-btn>

                                    <small class="spainhn-text my-auto ms-2 w-100">
                                        <span v-bind:class="{ 'd-none': !mostrarAforo }">
                                            {{$t("default.MENSAJE_AFORO") }}
                                        </span> 
                                        <strong v-bind:class="{ 'd-none': !mostrarPlazas }" 
                                            v-html="$t('default.MENSAJE_AFORO2', { plazas: `<span class='text-danger'>${horario.plazasDisponibles}</span>` })">
                                        </strong>
                                        <span> {{ horario.notaDeVenta }}</span>
                                    </small>
                                </div>
                            </div>

                        </template>

                    </div>

                    <div v-bind:class="{ 'd-none': (opcionesDeHorarios.length > 0 || loadingSchedule == true) }">
                        <p class="text-danger">{{ $t('default.RESERVATION_STEP_2_2') }}</p>
                    </div>

                    <p class="text-start" v-bind:class="{ 'd-none': !horarioLibre }">
                        <small class="spainhn-small-text">{{ $t('default.CART_STEP1_NOTES_1') }}
                        <br>
                        ({{$t('default.CART_STEP1_NOTES_2') }})
                        </small>
                    </p>

                </div>
            </div>
        </div>
    </mdb-container>
</template>

<script>
import { mdbContainer, mdbBtn } from 'mdbvue';
import moment from 'moment-timezone';
export default {

    components: {
        mdbContainer, mdbBtn
    },
    props: {
        loadingSchedule: {type: Boolean, required: false, default: false },
        mostrarAforo: {type: Boolean, required: false, default: false },
        mostrarPlazas: {type: Boolean, required: false, default: false },
        opcionesDeHorarios: { type: Array, default: () => []},
        horarioSelected: String,
        horarioData: String,
    },
    data(){
        return{
            mostrarOpcionesManual: false
        }
    },
    methods: {
        checkFreeTime( horarios ){
            if( horarios.length === 1 ){
                if( horarios[0].hora === null ){
                    return true;
                }else{
                    return false;
                }
            }
        },
        async setTarifa( producto ) {
            this.$emit('horarioSelected', producto._id )
            this.mostrarOpcionesManual = false;
        },
        isThisHorarioSelected( hora ){

        }
    },
    computed:{
        detallesDeLaSeleccion(){
            if( this.horarioSelected ){
                return this.horarioData
            }else{
                // return this.$t('default.RESERVATION_STEP_2')
                return "SELECCIONA UN HORA"
            }
        },
        mostrarOpciones(){
            if( this.mostrarOpcionesManual || ( this.opcionesDeHorarios.length && !this.horarioSelected ) ){
                return true;
            }else{
                return false;
            }
        },
        horarioLibre(){
            if( this.opcionesDeHorarios.length === 1 ){
                if( this.opcionesDeHorarios[0].hora === null ){
                    return true;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        }
    }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* -webkit-appearance: none; */
    margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
  } */

.horario-selected {
    background-color: rgba(204, 255, 255, 0.5) !important;
    width: 100%;
}

.center {
    /* position: fixed; */
    position: relative;
    /* top: 50%; */
    /* bottom: 0; */
    /* left: 50%; */
    /* right: 0; */
    /* margin: auto; */
    transform: translate(-50%, -50%);
    z-index: 10;
}

.active-item-option {
    background-color: rgba(204, 255, 255, 0.5) !important;
}

.item-option {
    background-color:#fcf8e3; width:100%
}

.collapsable-header {
    border-radius: 10px;
    background: #D9D9D9;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    /* max-width: 520px; */
}

.header-title-container {
    display: flex;
    height: 55px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.header-typhograpy {
    color: #FFF;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.header-active-typhograpy {
    color: var(--spainhn-buy-button);
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.finish-button {
    display: flex;
    padding: 5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 20px;
    background: #850B0B !important;
}

.finish-text {
    display: flex;
    width: 290px;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.loader-component {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>