var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "mdb-container",
    { staticClass: "flex flex-column px-0 my-2 align-items-center" },
    [
      _c("div", { staticClass: "collapsable-header mx-auto" }, [
        _c(
          "div",
          { staticClass: "header-title-container header-active-typhograpy" },
          [
            _c("span", { staticClass: "text-uppercase" }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.getTicketsAmount === 0
                      ? _vm.$t("default.RESERVATION_STEP_3")
                      : _vm.getTicketsAmount
                  ) +
                  " "
              ),
            ]),
          ]
        ),
        _c(
          "div",
          {
            staticClass: "animated fadeIn",
            class: { "d-none": !_vm.ticketsVisible },
            staticStyle: { width: "100%", "margin-top": "0" },
          },
          [
            _c("hr", { staticStyle: { color: "black", "margin-top": "0" } }),
            _c(
              "div",
              { staticStyle: { "text-align": "start !important" } },
              [
                [
                  _vm._l(_vm.grupoDeProductos, function (producto) {
                    return _c(
                      "mdb-row",
                      { key: producto._id },
                      [
                        _c("mdb-col", { attrs: { col: "12" } }, [
                          _c("h6", { staticClass: "text-color-spainhn" }, [
                            _vm._v(_vm._s(producto.nombre)),
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "w-100 spainhn-text pt-2 pb-2 px-2",
                            },
                            [
                              _c("strong", [
                                _vm._v(
                                  _vm._s(_vm.$t("default.RESERVATION_STEP_3_1"))
                                ),
                              ]),
                            ]
                          ),
                        ]),
                        _c(
                          "mdb-col",
                          { attrs: { col: "12" } },
                          [
                            _vm.grupoDePrecios[producto._id]
                              ? _vm._l(
                                  _vm.grupoDePrecios[producto._id],
                                  function (precio, index) {
                                    return _c(
                                      "mdb-container",
                                      {
                                        key: index,
                                        staticClass:
                                          "border-bottom py-2 border-top",
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "w-100 pe-3 text-wrap text-start spainhn-small-text",
                                            class: {
                                              "d-none":
                                                !precio.mensajeDeComision,
                                            },
                                          },
                                          [
                                            _c("small", [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "default.BOOKINGS.MENSAJE_COMISION_DE_GESTION",
                                                      {
                                                        msg: precio.mensajeDeComision,
                                                      }
                                                    )
                                                  ) +
                                                  " "
                                              ),
                                            ]),
                                          ]
                                        ),
                                        _c(
                                          "mdb-row",
                                          {
                                            staticClass:
                                              "justify-content-between",
                                          },
                                          [
                                            _c(
                                              "mdb-col",
                                              { attrs: { col: "12", md: "6" } },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column mx-auto text-center",
                                                  },
                                                  [
                                                    _c(
                                                      "strong",
                                                      {
                                                        staticClass:
                                                          "spainhn-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              precio
                                                                .descripciones[
                                                                _vm.idiomaIndex
                                                              ]?.texto
                                                            ) +
                                                            " "
                                                        ),
                                                        precio.helpers?.length
                                                          ? _c(
                                                              "span",
                                                              [
                                                                precio.helpers.filter(
                                                                  (h) =>
                                                                    h.idioma ===
                                                                    _vm.selectedIdioma
                                                                )[0]?.texto
                                                                  ? [
                                                                      _c(
                                                                        "mdb-tooltip",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              material:
                                                                                "",
                                                                              trigger:
                                                                                "hover",
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "span",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  slot: "tip",
                                                                                },
                                                                              slot: "tip",
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                " " +
                                                                                  _vm._s(
                                                                                    precio.helpers.filter(
                                                                                      (
                                                                                        h
                                                                                      ) =>
                                                                                        h.idioma ===
                                                                                        _vm.selectedIdioma
                                                                                    )[0]
                                                                                      ?.texto
                                                                                  ) +
                                                                                  " "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "font-awesome-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  slot: "reference",
                                                                                  icon: "question-circle",
                                                                                },
                                                                              slot: "reference",
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      ),
                                                                    ]
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                    _c(
                                                      "small",
                                                      {
                                                        staticClass:
                                                          "spainhn-text",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              precio.pvp.toLocaleString(
                                                                "es-ES",
                                                                {
                                                                  style:
                                                                    "currency",
                                                                  currency:
                                                                    "EUR",
                                                                }
                                                              )
                                                            ) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "mdb-col",
                                              { attrs: { col: "12", md: "6" } },
                                              [
                                                _c("booking-buttons", {
                                                  ref: "botoneras",
                                                  refInFor: true,
                                                  attrs: {
                                                    "min-personas":
                                                      precio?.minPersonas,
                                                    precioId: precio._id,
                                                    tarifaId: precio.tarifaId,
                                                    pvpUnitario: precio.pvp,
                                                    productoId: producto._id,
                                                    precioBase: precio.precio,
                                                    nombre:
                                                      _vm.getTextInLanguage(
                                                        precio.descripciones
                                                      ),
                                                    vinculaciones:
                                                      precio.preciosVinculados,
                                                    "maximo-incremento": -1,
                                                  },
                                                  on: {
                                                    change: (evt) =>
                                                      _vm.preciosEdit(
                                                        evt,
                                                        producto._id
                                                      ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                )
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  _c(
                    "div",
                    { staticClass: "clearfix" },
                    [
                      _c(
                        "mdb-btn",
                        {
                          staticClass:
                            "spainhn-button mt-3 mb-2 ms-auto float-right",
                          attrs: {
                            color: "indigo",
                            size: "lg",
                            disabled: _vm.deshabilitarAgregar,
                          },
                          on: { click: _vm.editarResumen },
                        },
                        [
                          _c("strong", [
                            _vm._v(
                              _vm._s(_vm.$t("default.SPAINHN_BUTTON_ADD_CART"))
                            ),
                          ]),
                          _c("br"),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
              ],
              2
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }