var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "default-layout",
    [
      _c(
        "div",
        {
          staticClass: "loader-component",
          class: { "d-none": !_vm.movingToCart && !_vm.loadingSchedule },
          attrs: { id: "loader" },
        },
        [
          _c("img", {
            staticClass: "animated flip infinite",
            staticStyle: { height: "220px", width: "auto" },
            attrs: {
              src: require("../assets/SimboloCastilloRojoCorporativa.svg"),
            },
          }),
        ]
      ),
      _c("div", { staticClass: "container-fluid px-md-5 px-2 mt-4 mb-4" }, [
        _c("div", { staticClass: "d-flex pe-0 w-100" }, [
          _c(
            "div",
            {
              staticClass: "text-start pt-3 pe-3",
              staticStyle: { width: "100%" },
            },
            [
              _c(
                "h4",
                {
                  staticClass: "my-auto pb-0 mb-0 spainhn-title",
                  attrs: { role: "button" },
                },
                [_c("strong", [_vm._v(" " + _vm._s(_vm.siteData.pageTitle))])]
              ),
              _c(
                "h5",
                {
                  staticClass: "mb-0",
                  class: { "d-none": !_vm.siteData.monumentName },
                },
                [
                  _c("strong", { staticClass: "spainhn-subtitle" }, [
                    _vm._v(_vm._s(_vm.siteData.monumentName)),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _c(
        "div",
        {
          staticClass: "container-fluid px-md-5 px-0 mt-0 px-0 mb-4",
          class: { "d-none": _vm.visitaFirstDate === null },
        },
        [
          _c(
            "div",
            {
              staticClass: "container-fluid col-md-8 col-12 mt-0 mx-auto",
              attrs: { id: "horarios-disponibles" },
            },
            [
              _vm.informacionDelProducto.opts.diasDiferidos
                ? [
                    _vm._l(_vm.productContainer, function (producto, index) {
                      return _c(
                        "mdb-container",
                        { key: index, attrs: { fluid: "" } },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "text-color-spainhn font-weight-bold",
                            },
                            [_vm._v(_vm._s(producto.nombre))]
                          ),
                          _c(
                            "b-card",
                            {
                              staticClass:
                                "d-flex pe-0 w-100 container px-0 px-md-0 mb-2",
                              staticStyle: { "background-color": "#D9D9D9" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex my-auto pb-3 text-center align-items-center",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "text-start center" },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          attrs: {
                                            xmlns: "http://www.w3.org/2000/svg",
                                            width: "20",
                                            height: "23",
                                            viewBox: "0 0 20 23",
                                            fill: "none",
                                          },
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d: "M13.0635 3.39704V1.26267M13.0635 3.39704V5.53141M13.0635 3.39704H8.35138M0.497803 9.80015V19.4048C0.497803 20.5836 1.43545 21.5392 2.59209 21.5392H17.2521C18.4088 21.5392 19.3464 20.5836 19.3464 19.4048V9.80015H0.497803Z",
                                              stroke: "#850B0B",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              d: "M0.497803 9.80015V5.53141C0.497803 4.35263 1.43545 3.39704 2.59209 3.39704H4.68638",
                                              stroke: "#850B0B",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              d: "M4.68628 1.26267V5.53141",
                                              stroke: "#850B0B",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          }),
                                          _c("path", {
                                            attrs: {
                                              d: "M19.3464 9.80015V5.53141C19.3464 4.35263 18.4088 3.39704 17.2521 3.39704H16.7285",
                                              stroke: "#850B0B",
                                              "stroke-linecap": "round",
                                              "stroke-linejoin": "round",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex",
                                      class: {
                                        "d-none": _vm.dataStepper[index].fecha,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "my-auto ms-3 fw-bold spainhn-sections-title",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                "default.RESERVATION_STEP_1"
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c("div", { staticClass: "d-flex ms-auto" }, [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex my-auto" },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "ms-auto d-flex" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "my-auto fw-bold spainhn-sections-title",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.dataStepper[index].fecha
                                                      ? _vm.dataStepper[
                                                          index
                                                        ].fecha.toLocaleDateString(
                                                          "es-ES",
                                                          { timeZone: "UTC" }
                                                        )
                                                      : null
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "ms-3" },
                                          [
                                            _c(
                                              "mdb-btn",
                                              {
                                                staticClass: "red darken-4",
                                                attrs: {
                                                  floating: "",
                                                  color: "red",
                                                },
                                                on: {
                                                  click: () =>
                                                    (_vm.dataStepper[
                                                      index
                                                    ].visibleDate =
                                                      !_vm.dataStepper[index]
                                                        .visibleDate),
                                                },
                                              },
                                              [
                                                _c("font-awesome-icon", {
                                                  attrs: {
                                                    role: "button",
                                                    icon: _vm.dataStepper[index]
                                                      .visibleDate
                                                      ? "fa-minus"
                                                      : "fa-plus",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "border-top mt-2 animated fadeIn",
                                  class: {
                                    "d-none":
                                      !_vm.dataStepper[index].visibleDate,
                                  },
                                  staticStyle: {
                                    "border-color":
                                      "rgb(165, 61, 57) !important",
                                    "border-width": "2px !important",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-md-8 col-12 mx-auto mt-4 p-md-3",
                                    },
                                    [
                                      _c("strong", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "default.CART_INSTRUCTIONS_1"
                                            )
                                          )
                                        ),
                                      ]),
                                      _vm.dataReady
                                        ? _c("vc-date-picker", {
                                            ref: "calendario",
                                            refInFor: true,
                                            staticClass:
                                              "reservasV2-date-picker",
                                            class: { "d-none": _vm.loading },
                                            attrs: {
                                              color: "red",
                                              "disabled-dates": [
                                                { start: null },
                                              ],
                                              timezone: "UTC",
                                              "available-dates":
                                                _vm.enabledDatesByProducts[
                                                  producto._id
                                                ],
                                              "is-expanded": "",
                                              is24hr: "",
                                              "min-date": _vm.minDate,
                                            },
                                            on: {
                                              "update:to-page": function (
                                                $event
                                              ) {
                                                return _vm.newMonth(
                                                  $event,
                                                  producto._id,
                                                  index
                                                )
                                              },
                                              dayclick: ($event) =>
                                                _vm.daySelected(
                                                  $event,
                                                  producto._id,
                                                  index
                                                ),
                                            },
                                            model: {
                                              value:
                                                _vm.dataStepper[index].fecha,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.dataStepper[index],
                                                  "fecha",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dataStepper[index].fecha",
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.loading
                                        ? _c(
                                            "div",
                                            { attrs: { id: "loader" } },
                                            [
                                              _c("img", {
                                                staticClass:
                                                  "animated flip infinite",
                                                staticStyle: {
                                                  height: "220px",
                                                  width: "auto",
                                                },
                                                attrs: {
                                                  src: require("../assets/SimboloCastilloRojoCorporativa.svg"),
                                                },
                                              }),
                                              _c("h5", [_vm._v("Cargando...")]),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "mdb-container",
                            { staticClass: "px-0" },
                            [
                              _c("SchedulePicker", {
                                attrs: {
                                  loadingSchedule: _vm.loadingSchedule,
                                  mostrarAforo: producto.mostrarAforo,
                                  mostrarPlazas: producto.aforo,
                                  opcionesDeHorarios:
                                    _vm.infoForEnabledDates[producto._id] ?? [],
                                  horarioSelected:
                                    _vm.seleccionesDelUsuario[producto._id]
                                      ?.horario?._id,
                                  horarioData:
                                    _vm.seleccionesDelUsuario[producto._id]
                                      ?.horario?.label,
                                },
                                on: {
                                  horarioSelected: (h) =>
                                    _vm.seleccionarHorario(h, producto._id),
                                },
                              }),
                            ],
                            1
                          ),
                          _c("hr"),
                        ],
                        1
                      )
                    }),
                    _c(
                      "mdb-container",
                      { staticClass: "mt-4 px-0" },
                      [
                        _c("selector-de-tarifas", {
                          attrs: {
                            productos: _vm.productContainer,
                            precios: _vm.preciosDisponiblesEnLaSeleccion,
                            permitirSeleccionDePrecios:
                              _vm.permitirControlDeSeleccionDePrecios,
                            bloquearBotones: _vm.movingToCart,
                            preciosEnPaquete: _vm.preciosEnPaquete,
                            seleccionDiferida: _vm.permitirSeleccionDiferida,
                            paqueteData: _vm.informacionDelProducto,
                          },
                          on: {
                            submitCart: (preciosSeleccionados) =>
                              _vm.goToCarrito(preciosSeleccionados),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : [
                    _c(
                      "b-card",
                      {
                        staticClass: "d-flex pe-0 w-100 container px-0 px-md-0",
                        staticStyle: { "background-color": "#D9D9D9" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex my-auto pb-3 text-center align-items-center",
                          },
                          [
                            _c("div", { staticClass: "text-start center" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    xmlns: "http://www.w3.org/2000/svg",
                                    width: "20",
                                    height: "23",
                                    viewBox: "0 0 20 23",
                                    fill: "none",
                                  },
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      d: "M13.0635 3.39704V1.26267M13.0635 3.39704V5.53141M13.0635 3.39704H8.35138M0.497803 9.80015V19.4048C0.497803 20.5836 1.43545 21.5392 2.59209 21.5392H17.2521C18.4088 21.5392 19.3464 20.5836 19.3464 19.4048V9.80015H0.497803Z",
                                      stroke: "#850B0B",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M0.497803 9.80015V5.53141C0.497803 4.35263 1.43545 3.39704 2.59209 3.39704H4.68638",
                                      stroke: "#850B0B",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M4.68628 1.26267V5.53141",
                                      stroke: "#850B0B",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d: "M19.3464 9.80015V5.53141C19.3464 4.35263 18.4088 3.39704 17.2521 3.39704H16.7285",
                                      stroke: "#850B0B",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                    },
                                  }),
                                ]
                              ),
                            ]),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex",
                                class: { "d-none": _vm.selectedDate },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "my-auto ms-3 fw-bold spainhn-sections-title",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("default.RESERVATION_STEP_1")
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "d-flex ms-auto",
                                class: { "d-none": _vm.selectedDate == null },
                              },
                              [
                                _c("div", { staticClass: "d-flex my-auto" }, [
                                  _c("div", { staticClass: "ms-auto d-flex" }, [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "my-auto fw-bold spainhn-sections-title",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.selectedDate
                                              ? _vm.selectedDate.toLocaleDateString(
                                                  "es-ES",
                                                  { timeZone: "UTC" }
                                                )
                                              : null
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c(
                                    "div",
                                    { staticClass: "ms-3" },
                                    [
                                      _c("font-awesome-icon", {
                                        directives: [
                                          {
                                            name: "b-toggle",
                                            rawName: "v-b-toggle.calendar",
                                            modifiers: { calendar: true },
                                          },
                                        ],
                                        staticClass:
                                          "circle-icon shadow spainhn-sections-title",
                                        attrs: {
                                          role: "button",
                                          icon: "fa-pen",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _c(
                          "b-collapse",
                          {
                            staticClass: "border-top mt-2",
                            staticStyle: {
                              "border-color": "rgb(165, 61, 57) !important",
                              "border-width": "2px !important",
                            },
                            attrs: { id: "calendar" },
                            model: {
                              value: _vm.visibleDate,
                              callback: function ($$v) {
                                _vm.visibleDate = $$v
                              },
                              expression: "visibleDate",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-md-8 col-12 mx-auto mt-4 p-md-3",
                              },
                              [
                                _c("strong", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t("default.CART_INSTRUCTIONS_1")
                                    )
                                  ),
                                ]),
                                _vm.dataReady
                                  ? _c("vc-date-picker", {
                                      ref: "calendario",
                                      staticClass: "reservasV2-date-picker",
                                      class: { "d-none": _vm.loading },
                                      attrs: {
                                        color: "red",
                                        "disabled-dates": [{ start: null }],
                                        timezone: "UTC",
                                        "available-dates": _vm.enabledDates,
                                        "is-expanded": "",
                                        is24hr: "",
                                        "min-date": _vm.minDate,
                                      },
                                      on: {
                                        "update:to-page": function ($event) {
                                          return _vm.newMonth($event)
                                        },
                                        dayclick: function ($event) {
                                          return _vm.daySelected($event)
                                        },
                                      },
                                      model: {
                                        value: _vm.selectedDate,
                                        callback: function ($$v) {
                                          _vm.selectedDate = $$v
                                        },
                                        expression: "selectedDate",
                                      },
                                    })
                                  : _vm._e(),
                                _vm.loading
                                  ? _c("div", { attrs: { id: "loader" } }, [
                                      _c("img", {
                                        staticClass: "animated flip infinite",
                                        staticStyle: {
                                          height: "220px",
                                          width: "auto",
                                        },
                                        attrs: {
                                          src: require("../assets/SimboloCastilloRojoCorporativa.svg"),
                                        },
                                      }),
                                      _c("h5", [_vm._v("Cargando...")]),
                                    ])
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._l(_vm.productContainer, function (producto) {
                      return _c(
                        "mdb-container",
                        { key: producto._id, staticClass: "px-0" },
                        [
                          _c(
                            "h6",
                            {
                              staticClass:
                                "text-right mt-3 header-active-typhograpy",
                            },
                            [_vm._v(_vm._s(producto.nombre))]
                          ),
                          _c("SchedulePicker", {
                            attrs: {
                              loadingSchedule: _vm.loadingSchedule,
                              mostrarAforo: producto.mostrarAforo,
                              mostrarPlazas: producto.aforo,
                              opcionesDeHorarios:
                                _vm.infoForEnabledDates[producto._id] ?? [],
                              horarioSelected:
                                _vm.seleccionesDelUsuario[producto._id]?.horario
                                  ?._id,
                              horarioData:
                                _vm.seleccionesDelUsuario[producto._id]?.horario
                                  ?.label,
                            },
                            on: {
                              horarioSelected: (h) =>
                                _vm.seleccionarHorario(h, producto._id),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    _c(
                      "mdb-container",
                      { staticClass: "mt-4 px-0" },
                      [
                        _c("selector-de-tarifas", {
                          attrs: {
                            productos: _vm.productContainer,
                            precios: _vm.preciosDisponiblesEnLaSeleccion,
                            permitirSeleccionDePrecios:
                              _vm.permitirControlDeSeleccionDePrecios,
                            bloquearBotones: _vm.movingToCart,
                          },
                          on: {
                            submitCart: (preciosSeleccionados) =>
                              _vm.goToCarrito(preciosSeleccionados),
                          },
                        }),
                      ],
                      1
                    ),
                  ],
              _vm.seats
                ? _c("seats-configurator", {
                    attrs: {
                      expanded: _vm.visibleSeats,
                      zonas: _vm.zonasInfo,
                      personas: _vm.getTicketsAmount,
                      originLayout: _vm.originLayout,
                      imagenIlustrativa: _vm.imagenIlustrativa,
                    },
                    on: { getAsientos: (evt) => _vm.setAsientos(evt) },
                  })
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "container-fluid px-5 mt-4 mb-4",
          class: { "d-none": _vm.visitaFirstDate != null },
        },
        [
          _c("div", { staticClass: "row margen-estandard d-flex" }, [
            _c("div", { staticClass: "col-md-7 h-100 my-auto" }, [
              _c(
                "div",
                {
                  staticClass:
                    "text-start d-flex pt-3 flex-column h-100 mt-auto",
                  staticStyle: { width: "100%" },
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass:
                        "my-3 pb-0 mb-0 spainhn-subtitle-black mx-auto mt-auto",
                      class: { "d-none": _vm.loading },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("default.SERVICES_BAR_ENTRADAS"))
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass:
                        "my-3 pb-0 mb-0 spainhn-title mx-auto spainhn-title-text3",
                      class: { "d-none": _vm.loading },
                      attrs: { role: "button" },
                    },
                    [
                      _c("strong", [
                        _vm._v(
                          " " +
                            _vm._s(_vm.monumentoNombres[_vm.idiomaIndex].texto)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mb-0 mx-auto",
                      class: { "d-none": _vm.loading },
                    },
                    [
                      _c("strong", { staticClass: "spainhn-subtitle" }, [
                        _vm._v(_vm._s(_vm.nombres[_vm.idiomaIndex].texto)),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "pt-0 d-flex my-3 mx-auto",
                      staticStyle: {
                        "margin-left": "6rem",
                        height: "300px",
                        width: "200px",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "img-fluid my-auto",
                        class: { "d-none": _vm.loading },
                        staticStyle: { height: "80px", width: "200px" },
                        attrs: { src: require("../assets/LOGO REPAHIS.png") },
                      }),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mx-auto",
                      class: { "d-none": _vm.loading },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.MENSAJE_SUGERENCIA_ACTIVIDAD"))
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "mx-auto",
                      class: { "d-none": _vm.loading },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.MENSAJE_AYUDA_PLANIFICACION"))
                      ),
                    ]
                  ),
                  _c(
                    "mdb-btn",
                    {
                      staticClass: "spainhn-button mx-auto",
                      class: { "d-none": _vm.loading },
                      attrs: { color: "indigo", href: "#" },
                      on: { click: _vm.goToContact },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.$t("default.MENSAJE_ORGANIZA_TU_RESERVA"))
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column pt-3 col-12 col-md-5 col-xxl-4",
                class: { "d-none": _vm.loading },
              },
              [
                _c(
                  "strong",
                  {
                    staticClass: "text-center mx-auto w-100 spainhn-subtitle-2",
                  },
                  [
                    _vm._v(
                      _vm._s(
                        _vm.$t("default.MICROSITES_TICKETS_RECOMMENDATIONS")
                      )
                    ),
                  ]
                ),
                _c("div", {
                  staticClass: "w-100 border-bottom",
                  staticStyle: {
                    height: "2vh",
                    "border-color":
                      "var(--spainhn-rojo-corporativo) !important",
                    "border-width": "medium",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap" },
                  _vm._l(_vm.eventosRecomendados, function (evento, index) {
                    return _c("cards-thumbnails-3", {
                      key: index,
                      class: { "d-none": index > 2 },
                      attrs: {
                        monument: evento.webName,
                        nombreUrl: evento.visitaId,
                        tipo: "Entrada",
                        text: evento.monumentName,
                        imageUrl: `${_vm.imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`,
                      },
                      on: { goTo: _vm.goToEntrada },
                    })
                  }),
                  1
                ),
              ]
            ),
          ]),
        ]
      ),
      _c(
        "mdb-container",
        { staticClass: "mt-3", attrs: { fluid: "" } },
        [_c("ComponentsCloud", { staticClass: "w-100" })],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "col-md-4 col-md-offset-4 col-xs-8 col-xs-offset-2",
          attrs: {
            id: "mensajes",
            "hide-footer": "",
            "hide-header": "",
            centered: "",
          },
          model: {
            value: _vm.reservaCancelada,
            callback: function ($$v) {
              _vm.reservaCancelada = $$v
            },
            expression: "reservaCancelada",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "col-xs-12",
              staticStyle: { "text-align": "right" },
            },
            [
              _c(
                "button",
                {
                  attrs: { type: "button" },
                  on: { click: () => (_vm.reservaCancelada = false) },
                },
                [
                  _c("img", {
                    attrs: {
                      src: require("@/assets/close.svg"),
                      width: "14px",
                      height: "14px",
                    },
                  }),
                ]
              ),
            ]
          ),
          _c("p", {
            staticClass: "alert sys-message",
            attrs: { role: "alert" },
          }),
          _c("div", { staticClass: "col-xs-12 justify-content-center" }, [
            _c("div", { staticClass: "d-flex" }, [
              _c("img", {
                staticClass: "mx-auto",
                attrs: {
                  src: require("@/assets/cancel-face.svg"),
                  width: "50px",
                  height: "50px",
                },
              }),
            ]),
            _c("div", { staticClass: "d-flex" }, [
              _c("p", { staticClass: "mx-auto spainhn-text" }, [
                _vm._v("Se ha cancelado la reserva correctamente"),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "d-flex" },
              [
                _c(
                  "mdb-btn",
                  {
                    staticClass: "mx-auto spainhn-button",
                    attrs: { color: "indigo" },
                    on: { click: () => (_vm.reservaCancelada = false) },
                  },
                  [_vm._v("Aceptar")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("WelcomeModal", {
        attrs: {
          popupStart: _vm.mostrarMensajeDeWelcome,
          title: _vm.welcomeMessageTitle,
          subtitle: _vm.welcomeMessageSubtitle,
        },
        on: { close: () => (_vm.mostrarMensajeDeWelcome = false) },
      }),
      _c("error-alert", {
        attrs: {
          show: _vm.asientosOcupadosAlert,
          mensaje: _vm.$t("default.SORRY_SEATS_MESSAGE"),
        },
        on: { close: () => _vm.refrescarAsientos() },
      }),
      _c("success-alert", {
        attrs: {
          show: _vm.itemAgregado,
          mensaje: "Se agregaron las entradas a su carrito",
        },
        on: { close: _vm.cerrarConfirmacion },
      }),
      _c("error-alert", {
        attrs: {
          show: _vm.itemEliminado,
          mensaje: "Se han eliminado entradas de su carrito",
        },
        on: { close: () => (_vm.itemEliminado = false) },
      }),
      _c("error-alert", {
        attrs: {
          cancel: true,
          carrito: true,
          show: _vm.itemDuplicadoAlert,
          mensaje:
            "Ya tiene otra entrada para la misma fecha y misma visita. \n¿Desea Continuar?",
        },
        on: { close: _vm.duplicarItem, cancelar: _vm.cancelarOperacion },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }