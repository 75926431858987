<template>
    <div>
        <mdb-row class="">
            <mdb-col class="text-center">
                <mdb-btn :disabled="cantidad == 0" color="red" class="red darken-3 text-white mx-auto"
                    @click="quitarPersonas">
                    <font-awesome-icon icon="fa-minus" />
                </mdb-btn>
            </mdb-col>
            <mdb-col class="my-auto bg-white py-0 text-center h-100 mx-auto">
                <span class="">{{ cantidad }}</span>
            </mdb-col>
            <mdb-col class="text-center">
                <mdb-btn :disabled="noIncrement" color="red" class="red darken-3 text-white mx-auto"
                    @click="agregarPersonas">
                    <font-awesome-icon icon="fa-plus" />
                </mdb-btn>
            </mdb-col>
        </mdb-row>
    </div>
</template>

<script>
import EventBus from '../../event-bus';
import { mdbRow, mdbCol, mdbBtn } from "mdbvue"
export default {
    props: {
        minPersonas: Number,
        precioId: String,
        pvpUnitario: Number,
        precioBase: Number,
        maximoIncremento: { Number, default: 0 },
        nombre: String,
        productoId: String,
        vinculaciones: { type: Array, default: () => [] }
    },
    components: {
        mdbRow, mdbCol, mdbBtn
    },
    data() {
        return {
            cantidad: 0,
            // noIncrement: false
        }
    },
    methods: {
        reset() {
            this.cantidad = 0;
        },
        quitarPersonas() {
            if (this.cantidad - 1 >= this.minPersonas) {
                this.cantidad -= 1;
                // this.$store.commit('quitarBoleto', 1);
            }
            else {
                // this.$store.commit('quitarBoleto', this.minPersonas);
                this.cantidad = 0;
            }

            if (this.cantidad < 0) {
                this.cantidad = 0;
            }

            this.$emit('change', { precioId: this.precioId, cantidad: this.cantidad, pvpUnitario: this.pvpUnitario, 
                nombre: this.nombre, precioBase: this.precioBase, vinculaciones: this.vinculaciones })
        },
        agregarPersonas() {
            if (this.cantidad + 1 <= this.minPersonas) {
                this.cantidad = this.minPersonas;
                // this.$store.commit('agregarBoleto', this.minPersonas)
            }
            else {
                this.cantidad += 1;
                // this.$store.commit('agregarBoleto', 1)
            }
            this.$emit('change', { precioId: this.precioId, cantidad: this.cantidad, pvpUnitario: this.pvpUnitario, 
                nombre: this.nombre, precioBase: this.precioBase, vinculaciones: this.vinculaciones })
        }
    },
    computed: {
        noIncrement() {
            // console.log(this.maximoIncremento);
            if (this.$store.getters.limitarNumeroDePlazas === false) {
                return false;
            }
            else {
                let proximasPlazas = 0;

                if (this.cantidad === 0) {
                    const boletosPorProducto = this.$store.getters.boletosEnCadaProducto;
                    if (boletosPorProducto) {
                        const boletosEnEsteProducto = boletosPorProducto[this.productoId] ?? 0;
                        proximasPlazas = boletosEnEsteProducto + this.minPersonas;
                    } else {
                        proximasPlazas = 0;
                    }
                }
                else {
                    const boletosPorProucto = this.$store.getters.boletosEnCadaProducto;
                    if (boletosPorProucto) {
                        const boletosEnEsteProducto = boletosPorProucto[this.productoId] ?? 0;
                        proximasPlazas = boletosEnEsteProducto + 1;
                    } else {
                        proximasPlazas = 0;
                    }
                }

                if (proximasPlazas > this.$store.getters.espacioParaBoletos) {
                    return true
                }
                else {
                    return false;
                }
            }
        },
        // cantidadBoletos(){
        //     return this.cantidad;
        // }
    },
    mounted() {
        EventBus.$on("resetBoletos", () => {
            this.reset();
        });
    }
}
</script>