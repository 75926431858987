<template>
    <default-layout>

        <div id="loader" :class="{ 'd-none': !movingToCart && !loadingSchedule }" class="loader-component">
            <img src="../assets/SimboloCastilloRojoCorporativa.svg" style="height:220px; width:auto;"
                class="animated flip infinite" />
        </div>

        <div class="container-fluid px-md-5 px-2 mt-4 mb-4">

            <div class="d-flex pe-0 w-100">
                <div class="text-start pt-3 pe-3" style="width:100%">
                    <h4 class="my-auto pb-0 mb-0 spainhn-title" role="button"><strong>
                            {{ siteData.pageTitle }}</strong> </h4>
                    <h5 class="mb-0" :class="{'d-none': !siteData.monumentName}"><strong class="spainhn-subtitle">{{ siteData.monumentName }}</strong></h5>
                </div>
            </div>
        </div>

        <div class="container-fluid px-md-5 px-0 mt-0 px-0 mb-4"
            v-bind:class="{ 'd-none': (visitaFirstDate === null) }">

            <div class="container-fluid col-md-8 col-12 mt-0  mx-auto" id="horarios-disponibles">

                <template v-if="informacionDelProducto.opts.diasDiferidos">
                    <mdb-container v-for="(producto, index) of productContainer" :key="index" fluid>
                        <h6 class="text-color-spainhn font-weight-bold">{{ producto.nombre }}</h6>
                        <b-card class="d-flex pe-0 w-100 container px-0 px-md-0 mb-2"
                            style="background-color: #D9D9D9;">

                            <div class="d-flex my-auto pb-3 text-center align-items-center">
                                <div class="text-start center">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23"
                                        fill="none">
                                        <path
                                            d="M13.0635 3.39704V1.26267M13.0635 3.39704V5.53141M13.0635 3.39704H8.35138M0.497803 9.80015V19.4048C0.497803 20.5836 1.43545 21.5392 2.59209 21.5392H17.2521C18.4088 21.5392 19.3464 20.5836 19.3464 19.4048V9.80015H0.497803Z"
                                            stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                                        <path
                                            d="M0.497803 9.80015V5.53141C0.497803 4.35263 1.43545 3.39704 2.59209 3.39704H4.68638"
                                            stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M4.68628 1.26267V5.53141" stroke="#850B0B" stroke-linecap="round"
                                            stroke-linejoin="round" />
                                        <path
                                            d="M19.3464 9.80015V5.53141C19.3464 4.35263 18.4088 3.39704 17.2521 3.39704H16.7285"
                                            stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                                <div class="d-flex" v-bind:class="{ 'd-none': dataStepper[index].fecha }">
                                    <span class="my-auto ms-3 fw-bold spainhn-sections-title">{{
                                        $t('default.RESERVATION_STEP_1') }}</span>
                                </div>

                                <div class="d-flex ms-auto">
                                    <div class="d-flex my-auto">
                                        <div class="ms-auto d-flex">
                                            <span class="my-auto fw-bold spainhn-sections-title">{{ dataStepper[index].fecha ?
                                                dataStepper[index].fecha.toLocaleDateString('es-ES', { timeZone: 'UTC' }) : null
                                                }}</span>
                                        </div>
                                        <div class="ms-3">
                                            <mdb-btn floating color="red" class="red darken-4"
                                                @click="() => dataStepper[index].visibleDate = !dataStepper[index].visibleDate" 
                                                >
                                                <font-awesome-icon role="button"
                                                    class="" :icon="dataStepper[index].visibleDate ? 'fa-minus' : 'fa-plus'"/>
                                            </mdb-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="border-top mt-2 animated fadeIn"
                                style="border-color: rgb(165, 61, 57) !important; border-width: 2px !important;"
                                v-bind:class="{ 'd-none': !dataStepper[index].visibleDate }">
                                <div class="col-md-8 col-12 mx-auto mt-4 p-md-3">
                                    <strong>{{ $t('default.CART_INSTRUCTIONS_1') }}</strong>
                                    <vc-date-picker :color="'red'" @update:to-page="newMonth($event, producto._id, index)" v-if="dataReady"
                                        :disabled-dates="[{ start: null }]" :timezone="'UTC'"
                                        :available-dates="enabledDatesByProducts[producto._id]" is-expanded
                                        @dayclick="($event) => daySelected($event, producto._id, index)" ref="calendario"
                                        v-model="dataStepper[index].fecha" 
                                        is24hr 
                                        v-bind:class="{ 'd-none': loading }"
                                        class="reservasV2-date-picker" 
                                        :min-date="minDate"/>

                                    <div id="loader" v-if="loading">
                                        <img src="../assets/SimboloCastilloRojoCorporativa.svg"
                                            style="height:220px; width:auto;" class="animated flip infinite" />
                                        <h5>Cargando...</h5>
                                    </div>

                                </div>

                            </div>
                        </b-card>

                        <mdb-container class="px-0">
                            <!-- <h6 class="text-right mt-3 header-active-typhograpy">{{ producto.nombre }}</h6> -->
                            <SchedulePicker 
                                :loadingSchedule="loadingSchedule" 
                                :mostrarAforo="producto.mostrarAforo"
                                :mostrarPlazas="producto.aforo"
                                :opcionesDeHorarios="infoForEnabledDates[producto._id] ?? []"
                                :horarioSelected="seleccionesDelUsuario[producto._id]?.horario?._id"
                                :horarioData="seleccionesDelUsuario[producto._id]?.horario?.label"
                                @horarioSelected="(h) => seleccionarHorario(h, producto._id)" />
                        </mdb-container>                        
                        <hr>
                    </mdb-container>

                    <mdb-container class="mt-4 px-0">
                        <selector-de-tarifas
                            :productos="productContainer" 
                            :precios="preciosDisponiblesEnLaSeleccion"
                            :permitirSeleccionDePrecios="permitirControlDeSeleccionDePrecios"
                            :bloquearBotones="movingToCart"
                            :preciosEnPaquete="preciosEnPaquete"
                            :seleccionDiferida="permitirSeleccionDiferida"
                            :paqueteData="informacionDelProducto"
                            @submitCart="(preciosSeleccionados) => goToCarrito(preciosSeleccionados)" />
                    </mdb-container>
                </template>

                <template v-else>
                    <b-card class="d-flex pe-0 w-100 container px-0 px-md-0" style="background-color: #D9D9D9;">

                        <div class="d-flex my-auto pb-3 text-center align-items-center">
                            <div class="text-start center">
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="23" viewBox="0 0 20 23"
                                    fill="none">
                                    <path
                                        d="M13.0635 3.39704V1.26267M13.0635 3.39704V5.53141M13.0635 3.39704H8.35138M0.497803 9.80015V19.4048C0.497803 20.5836 1.43545 21.5392 2.59209 21.5392H17.2521C18.4088 21.5392 19.3464 20.5836 19.3464 19.4048V9.80015H0.497803Z"
                                        stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                                    <path
                                        d="M0.497803 9.80015V5.53141C0.497803 4.35263 1.43545 3.39704 2.59209 3.39704H4.68638"
                                        stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                                    <path d="M4.68628 1.26267V5.53141" stroke="#850B0B" stroke-linecap="round"
                                        stroke-linejoin="round" />
                                    <path
                                        d="M19.3464 9.80015V5.53141C19.3464 4.35263 18.4088 3.39704 17.2521 3.39704H16.7285"
                                        stroke="#850B0B" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                                <!-- <font-awesome-icon icon="fa-calendar" class="fa-2x ms-2"/> -->
                            </div>
                            <div class="d-flex" v-bind:class="{ 'd-none': selectedDate }">
                                <span class="my-auto ms-3 fw-bold spainhn-sections-title">{{
                                    $t('default.RESERVATION_STEP_1') }}</span>
                            </div>

                            <div class="d-flex ms-auto" v-bind:class="{ 'd-none': selectedDate == null }">
                                <div class="d-flex my-auto">
                                    <div class="ms-auto d-flex">
                                        <span class="my-auto fw-bold spainhn-sections-title">{{ selectedDate ?
                                            selectedDate.toLocaleDateString('es-ES', { timeZone: 'UTC' }) : null
                                            }}</span>
                                    </div>
                                    <div class="ms-3">
                                        <font-awesome-icon role="button" v-b-toggle.calendar
                                            class="circle-icon shadow spainhn-sections-title" icon="fa-pen" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <b-collapse id="calendar" class="border-top mt-2"
                            style="border-color: rgb(165, 61, 57) !important; border-width: 2px !important;"
                            v-model="visibleDate">
                            <div class="col-md-8 col-12 mx-auto mt-4 p-md-3">
                                <strong>{{ $t('default.CART_INSTRUCTIONS_1') }}</strong>
                                <vc-date-picker :color="'red'" @update:to-page="newMonth($event)" v-if="dataReady"
                                    :disabled-dates="[{ start: null }]" :timezone="'UTC'"
                                    :available-dates="enabledDates" is-expanded @dayclick="daySelected($event)"
                                    ref="calendario" v-model="selectedDate" is24hr v-bind:class="{ 'd-none': loading }"
                                    class="reservasV2-date-picker" :min-date="minDate" />

                                <div id="loader" v-if="loading">
                                    <img src="../assets/SimboloCastilloRojoCorporativa.svg"
                                        style="height:220px; width:auto;" class="animated flip infinite" />
                                    <h5>Cargando...</h5>
                                </div>

                            </div>

                        </b-collapse>
                    </b-card>

                    <mdb-container v-for="producto in productContainer" :key="producto._id" class="px-0">
                        <h6 class="text-right mt-3 header-active-typhograpy">{{ producto.nombre }}</h6>
                        <SchedulePicker 
                            :loadingSchedule="loadingSchedule" 
                            :mostrarAforo="producto.mostrarAforo"
                            :mostrarPlazas="producto.aforo"
                            :opcionesDeHorarios="infoForEnabledDates[producto._id] ?? []"
                            :horarioSelected="seleccionesDelUsuario[producto._id]?.horario?._id"
                            :horarioData="seleccionesDelUsuario[producto._id]?.horario?.label"
                            @horarioSelected="(h) => seleccionarHorario(h, producto._id)" />
                    </mdb-container>

                    <mdb-container class="mt-4 px-0">
                        <selector-de-tarifas 
                            :productos="productContainer" 
                            :precios="preciosDisponiblesEnLaSeleccion"
                            :permitirSeleccionDePrecios="permitirControlDeSeleccionDePrecios"
                            :bloquearBotones="movingToCart"
                            @submitCart="(preciosSeleccionados) => goToCarrito(preciosSeleccionados)" />
                    </mdb-container>
                </template>

                <seats-configurator v-if="seats" :expanded="visibleSeats" :zonas="zonasInfo"
                    :personas="getTicketsAmount" @getAsientos="(evt) => setAsientos(evt)" :originLayout="originLayout"
                    :imagenIlustrativa="imagenIlustrativa">

                </seats-configurator>
            </div>

        </div>

        <div class="container-fluid px-5 mt-4 mb-4" v-bind:class="{ 'd-none': ((visitaFirstDate != null)) }">
            <div class="row margen-estandard d-flex">
                <div class="col-md-7 h-100 my-auto">
                    <div class="text-start d-flex pt-3 flex-column h-100 mt-auto" style="width:100%">
                        <h4 class="my-3 pb-0 mb-0 spainhn-subtitle-black mx-auto mt-auto"
                            v-bind:class="{ 'd-none': loading }">
                            <strong> {{ $t("default.SERVICES_BAR_ENTRADAS")
                                }}</strong>
                        </h4>
                        <p class="my-3 pb-0 mb-0 spainhn-title mx-auto spainhn-title-text3" role="button"
                            v-bind:class="{ 'd-none': loading }"><strong>
                                {{ monumentoNombres[idiomaIndex].texto }}</strong> </p>
                        <p class="mb-0 mx-auto" v-bind:class="{ 'd-none': loading }"><strong class="spainhn-subtitle">{{
                            nombres[idiomaIndex].texto }}</strong></p>
                        <div class="pt-0 d-flex my-3 mx-auto" style="margin-left:6rem; height:300px; width:200px">
                            <img src="../assets/LOGO REPAHIS.png" class="img-fluid my-auto"
                                style="height:80px;width:200px" v-bind:class="{ 'd-none': loading }" />
                        </div>

                        <p class="mx-auto" v-bind:class="{ 'd-none': loading }">{{
                            $t("default.MENSAJE_SUGERENCIA_ACTIVIDAD") }}</p>
                        <p class="mx-auto" v-bind:class="{ 'd-none': loading }">{{
                            $t("default.MENSAJE_AYUDA_PLANIFICACION") }}</p>
                        <mdb-btn color="indigo" class="spainhn-button mx-auto" @click="goToContact" href="#"
                            v-bind:class="{ 'd-none': loading }">{{ $t("default.MENSAJE_ORGANIZA_TU_RESERVA")
                            }}</mdb-btn>

                    </div>
                </div>

                <div class="d-flex flex-column pt-3 col-12 col-md-5 col-xxl-4" v-bind:class="{ 'd-none': loading }">
                    <strong class="text-center mx-auto w-100 spainhn-subtitle-2">{{
                        $t('default.MICROSITES_TICKETS_RECOMMENDATIONS') }}</strong>
                    <div class="w-100 border-bottom"
                        style="height:2vh; border-color:var(--spainhn-rojo-corporativo) !important; border-width: medium;">
                    </div>
                    <div class="d-flex flex-wrap">
                        <!-- Poner aquí las visitas -->
                        <cards-thumbnails-3 v-for="(evento, index) in eventosRecomendados" v-bind:key="index"
                            :monument="evento.webName" 
                            :nombreUrl="evento.visitaId"
                             @goTo="goToEntrada" 
                             :tipo="'Entrada'"
                            :text="evento.monumentName"
                            :imageUrl="`${imagenesUrl}/visitas_castillos/${evento.oldId}/${evento.imagen}`"
                            v-bind:class="{ 'd-none': index > 2 }" />
                    </div>

                </div>

            </div>
        </div>

        <mdb-container fluid class="mt-3">
            <ComponentsCloud class="w-100" />
        </mdb-container>

        <b-modal v-model="reservaCancelada" id="mensajes" class="col-md-4 col-md-offset-4 col-xs-8 col-xs-offset-2"
            hide-footer hide-header centered>
            <div class="col-xs-12" style="text-align: right;">
                <button @click="() => reservaCancelada = false" type="button">
                    <img src="@/assets/close.svg" width="14px" height="14px">
                </button>
            </div>
            <p class="alert sys-message" role="alert"></p>
            <div class="col-xs-12 justify-content-center">
                <div class="d-flex">
                    <img class="mx-auto" src="@/assets/cancel-face.svg" width="50px" height="50px">
                </div>
                <div class="d-flex">
                    <p class="mx-auto spainhn-text">Se ha cancelado la reserva correctamente</p>
                </div>
                <div class="d-flex">
                    <mdb-btn color="indigo" class="mx-auto spainhn-button"
                        @click="() => reservaCancelada = false">Aceptar</mdb-btn>
                </div>
            </div>
        </b-modal>

        <WelcomeModal :popupStart="mostrarMensajeDeWelcome" :title="welcomeMessageTitle"
            :subtitle="welcomeMessageSubtitle" @close="() => mostrarMensajeDeWelcome = false" />
        <error-alert :show="asientosOcupadosAlert" :mensaje="$t('default.SORRY_SEATS_MESSAGE')"
            @close="() => refrescarAsientos()" />
        <success-alert :show="itemAgregado" @close="cerrarConfirmacion"
            :mensaje="'Se agregaron las entradas a su carrito'"></success-alert>

        <error-alert :show="itemEliminado" @close="() => itemEliminado = false"
            :mensaje="'Se han eliminado entradas de su carrito'"></error-alert>
        <error-alert :cancel="true" :carrito="true" :show="itemDuplicadoAlert" @close="duplicarItem"
            @cancelar="cancelarOperacion"
            :mensaje="'Ya tiene otra entrada para la misma fecha y misma visita. \n¿Desea Continuar?'" />
    </default-layout>
</template>

<script>
import DefaultLayout from '../layouts/DefaultLayout.vue'
import ApiService from '../services/ApiService';
// import { uuid as uuid } from "vue-uuid";
import { v4 as uuidv4 } from 'uuid';
import CardsThumbnails3 from '../components/CardsThumbnails3.vue'
import SuccessAlert from '../components/SuccessAlert.vue';
import ErrorAlert from '../components/ErrorAlert.vue';
import ClorianService from '../services/ClorianService';

import moment from 'moment-timezone'
import EventBus from '../event-bus';
import Seats from '../components/Seats.vue'

import CollapsableElement from '../components/CollapsableElement.vue';
import ComponentsCloud from '../components/ComponentsCloud';
import WelcomeModal from '../components/WelcomeModal.vue';
import SchedulePicker from '../components/ReservasComponents/SchedulePicker.vue';
import SelectorDeTarifas from '../components/ReservasComponents/SelectorDeTarifas.vue';

export default {
    components: {
        DefaultLayout,
        CardsThumbnails3,
        SuccessAlert,
        ErrorAlert,
        // BotonesReserva,
        'seats-configurator': Seats,
        ComponentsCloud,
        WelcomeModal,
        SchedulePicker,
        SelectorDeTarifas
    },
    data() {
        return {
            selectedDate: null,
            consultandoDisponibilidad: false,
            monumentoNombres: [{ texto: "" }, { texto: "" }, { texto: "" }, { texto: "" }],
            enabledDates: [],
            enabledDatesByProducts: {},
            // Dictionary with the productId and the dates for that product
            infoForEnabledDates: {},
            monumentoId: null,
            visitaId: null,
            nombres: [{ texto: "" }, { texto: "" }, { texto: "" }, { texto: "" }],
            siteData: {
                webName: "",
                productsName: [],
                pageTitle: "",
                monumentName: ""
            },
            idiomaIndex: 0,
            precios: [],
            resumen: [],
            selected: [],
            cantidadBoletos: 0,
            eventosRecomendados: [],
            tipoDeEntrada: "Tarifas",
            disponibilidadInfo: null,
            cargandoDisponibilidad: true,
            horariosInfo: [],
            horarioSelected: null,
            itemUuid: null,
            tipoComision: null,
            comision: null,
            visitaFirstDate: null,
            fechasDesactivadas: [{ 'start': null }],
            resumenFields: [
                { key: "cantidad", label: 'Cantidad' },
                { key: "descripcion", label: "Tipo" },
                { key: "precio", label: "Precio Ud." },
                { key: "total", label: "Precio Total" }
            ],
            attrs: [
                {
                    contentStyle: {
                        color: 'red'
                    }
                }],
            total: 0,
            baseTotal: 0,
            comisionTotal: 0,
            descuentoTotal: 0,
            condicionComercial: null,
            descuentoComercial: null,
            tipoCobro: true,
            iva: 0.21,
            cancelable: false,
            loading: true,
            loadingSchedule: false,
            imagenesUrl: null,
            simbolo: "%",
            productoAgregado: false,
            itemAgregado: false,
            itemEliminado: false,
            carritoItemTemp: null,
            itemDuplicadoAlert: false,
            mostrarAforo: false,
            reservaCancelada: false,
            mostrarPlazas: false,
            clorianRatesInfo: null,
            dateSelected: new Date(),
            clorian: false,
            pos: null,
            visibleDate: true,
            visibleHours: false,
            ticketsVisible: false,
            visibleCheckout: false,
            cantidades: [],
            cantidadTotal: 0,
            tarifaId: null,
            seats: false,
            visibleSeats: false,
            zonasInfo: [],
            selectedSeats: [],
            zonaDeBoletos: null,
            numeroDeAsientos: null,
            imagenIlustrativa: null,
            originLayout: null,
            asientosOcupadosAlert: false,
            noChargeForFreeTickets: false,
            cantidadesReady: false,
            autocompensar: true,
            metodoDeFacturacion: 'visita',
            welcomeActive: false,
            welcomeMessageTitle: "",
            welcomeMessageSubtitle: "",
            mostrarMensajeDeWelcome: false,
            welcomeMessageImage: "",
            movingToCart: true,
            consultarBienvenida: false,

            informacionDelProducto: {
                _id: '',
                tipo: "",
                titulo: "",
                info: [],
                aforo: 0,
                mostrarAforo: false,
                infoPaquete: [],
                opts: {},
                tarifaId: null,
            },
            productContainer: [],
            calendarioDeFechas: [],
            dataReady: false,
            minDate: new Date(),
            pageTitle: "",
            // Aquí guardamos las horas
            seleccionesDelUsuario: {},
            preciosDisponiblesEnLaSeleccion: {},
            informacionComercial: {},
            tipoDePaquete: "",
            dataStepper: [],
            activeStep: 0,
            preciosEnPaquete: [],
            permitirSeleccionDiferida: true,
        }
    },
    mounted() {
        this.imagenesUrl = process.env.VUE_APP_IMAGES_URL;
    },
    methods: {
        checkFreeTime(horarios) {
            if (horarios.length === 1) {
                if (horarios[0].hora === null) {
                    return true;
                } else {
                    return false;
                }
            }
        },
        refrescarAsientos() {
            this.asientosOcupadosAlert = false;
            window.location.reload();
        },
        scrollToRates() {
            this.$refs["ratesPicker"].scrollIntoView({ behavior: "smooth" })
        },
        getPlazasDisponibles(plazas, plazasOcupadas, plazasReservadas) {
            if (plazas != null && plazas != 0) {
                let ocup = plazasOcupadas ?? 0;
                let reserv = plazasReservadas != null && plazasReservadas >= 0 ? plazasReservadas : 0;

                if (reserv < 0) {
                    reserv = 0;
                }
                // if(!plazasOcupadas || !plazasReservadas){
                //     return plazas;
                // }
                // else{
                return plazas - ocup - reserv;
                // }
            }
            else {
                return 'Sin límite'
            }
        },

        cerrarConfirmacion() {
            let $vm = this;
            $vm.itemAgregado = false;
        },

        isThisHorarioSelected(hora) {
            let $vm = this;
            if ($vm.horarioSelected) {
                if ($vm.horarioSelected.hora == hora) {
                    return true;
                }
                else {
                    return false;
                }
            }
            else {
                return false;
            }
        },

        preciosEdit(evt) {
            this.cantidades[evt.tarifa] = evt.cantidad;

            this.cantidadesReady = this.cantidades.reduce((acc, curr) => acc + curr, 0) > 0 ? true : false;
        },
        setAsientos(payload) {
            this.visibleCheckout = true;
            this.visibleSeats = false;
            this.selectedSeats = payload.asientosInfo;
            this.zonaDeBoletos = payload.zonaName;
            this.numeroDeAsientos = payload.asientosResumen;

            this.scrollToRates();
        },

        verificarDuplicidad(nuevoItem) {
            let $vm = this;
            let entradas = $vm.$store.getters.itemsInCart;
            let entradasDuplicadas = [];
            entradasDuplicadas = entradas.filter(entrada => {
                if ((entrada.itemIDVisita == nuevoItem.itemIDVisita) && (entrada.itemFecha == nuevoItem.itemFecha)) {
                    return true;
                }
                else {
                    return false;
                }
            })

            if (entradasDuplicadas.length > 0) {
                return true;
            }
            else {
                return false;
            }
        },
        goToCastillosYPalacios() {
            let $vm = this;
            $vm.$router.push('/castillos')
        },
        goToCastillos() {
            let $vm = this;
            $vm.$router.push('/castillos/' + $vm.monumentoNombres[0]?.texto)
        },

        async verificarAsientosDisponibles() {

            const filas = this.selectedSeats.map(s => s.filaNombre);
            const uniqueFilas = filas.filter((f, index, arry) => {
                return arry.indexOf(f) === index
            })

            const seatsId = this.selectedSeats.map(s => s.columna);
            const result = await ApiService.verifySeats(seatsId, this.zonaDeBoletos, this.horarioSelected.configuracionAsientos, uniqueFilas);
            return result.data.available;
        },
        async goToCarrito(preciosSeleccionados) {
            let $vm = this;
            this.movingToCart = true;
            const productos = this.informacionDelProducto.infoPaquete.map(p => p._id);
            // Este id va a identificar los items que se compraron juntos
            const groupId = uuidv4();
            const itemsQueSeAgregaranACarrito = [];
            // Aquí agregamos al carrito cada producto que se encuentra dentro del paquete / producto
            this.informacionDelProducto.infoPaquete.forEach(p => {
                const _itemUuid = uuidv4()
                const preciosSeleccionadosPorElUsuario = preciosSeleccionados.get(p._id);
                console.log( this.seleccionesDelUsuario );
                // const horarioSeleccionadoPorElUsuario = this.seleccionesDelUsuario[p._id];
                const horarioSeleccionadoPorElUsuario = Object.values(this.seleccionesDelUsuario).map( h => h.horario)
                    .reduce( ( acc, curr) => acc.concat(curr), [] );

                let _selectedDate = '';
                if( this.informacionDelProducto.opts.diasDiferidos ){
                    const tempDateInfo = this.dataStepper.map( d => {
                        return moment(d.fecha).utc().startOf('d').tz('Europe/Madrid').format();
                    })
                    _selectedDate = tempDateInfo.join('|');
                }else{
                    _selectedDate = moment(this.selectedDate).utc().startOf('d').tz('Europe/Madrid').format();
                }

                const detallesDelItem = {
                    idDelHorario: horarioSeleccionadoPorElUsuario.map( horario => horario._id ).join('|'),
                    horaSeleccionada: horarioSeleccionadoPorElUsuario.map( horario => horario.hora ).join('|'),
                    tarifaId: horarioSeleccionadoPorElUsuario.map( horario => horario.tarifa ).join('|')
                }
                const informacionComercialDelProducto = this.informacionComercial[p._id];
                const detallesDeTickets = [];

                let descuento = null;
                let comision = null;
                let tipo_comision = informacionComercialDelProducto.tipo;

                if (tipo_comision === 3 || tipo_comision === 1) {
                    if (tipo_comision === 1) {
                        comision = informacionComercialDelProducto.valor;
                    } else {
                        comision = informacionComercialDelProducto.valor / 100;
                    }
                } else {
                    if (tipo_comision === 2) {
                        descuento = informacionComercialDelProducto.valor / 100;
                    } else {
                        descuento = informacionComercialDelProducto.valor;
                    }
                }

                const todosLosPrecios = Object.values(this.preciosDisponiblesEnLaSeleccion);

                Object.values(preciosSeleccionadosPorElUsuario).forEach((ps) => {
                    if (ps) {
                        if (ps.cantidad) {
                            detallesDeTickets.push({
                                cantidad: ps.cantidad,
                                descripcion: ps.nombre,
                                tipo_comision,
                                comision,
                                descuento,
                                precio: ps.precio,
                                total: ps.pvpUnitario * ps.cantidad,
                                pvp: ps.pvpUnitario,
                                importeBase: ps.precio * ps.cantidad,
                                autocompensado: informacionComercialDelProducto.autocompensado ?? true,
                                preciosVinculados: ps.vinculaciones
                            })
                        }
                    }
                });

                const total = detallesDeTickets.map(t => t.total).reduce((acc, pvp) => acc + pvp, 0);

                const _informacionInidividualDeProductos = this.informacionDelProducto.info.map( prod => {
                    if( prod.tipo !== 'servicios' && this.tipoDePaquete === 'monumentos'){
                        const itemID = this.seleccionesDelUsuario[prod._id]?.horario._id;
                        const itemHorario = this.seleccionesDelUsuario[prod._id]?.horario.hora
                        const itemFecha = moment(this.dataStepper.find( d => d.producto === prod._id).fecha).utc().startOf('d').tz('Europe/Madrid');
                        // const itemTarifaId = preciosSeleccionadosPorElUsuario[p._id].estadisticas
                        return {...prod, itemID, itemHorario, itemFecha };
                    }else{
                        return { ...prod }
                    }
                })
                let _tipoDePaquete = this.tipoDePaquete;
                // const ResumenDeFechas = 
                let carritoItem = {
                    itemID: detallesDelItem.idDelHorario,
                    itemClorianID: null,
                    // TODO: MODIFICAR POR PRODUCTO CONTENIDO EN EL PAQUETE
                    itemIDVisita: this.informacionDelProducto._id,
                    itemUuid: _itemUuid,
                    itemTarifaId: this.tipoDePaquete === 'monumentos' ? this.informacionDelProducto.tarifaId : detallesDelItem.tarifaId,
                    itemHorario: detallesDelItem.horaSeleccionada,
                    itemFecha: _selectedDate,
                    itemTotal: total,
                    itemTickets: [...detallesDeTickets],
                    itemVisita: this.siteData.webName || this.informacionDelProducto?.titulo,
                    productosQueSeIncluyen: [ ..._informacionInidividualDeProductos ],
                    itemMonumentoId: this.informacionDelProducto.ownerId,
                    itemMonumento: this.informacionDelProducto.ownerName,
                    itemComision: comision,
                    itemComisionPorcentaje: tipo_comision,
                    cancelable: p.cancelable ?? false,
                    seguroCancelacion: false,
                    montoCancelacion: 0,
                    timestamp: new Date(),
                    pos: null,
                    carritoUuid: null,
                    reservationId: null,
                    asientos: this.selectedSeats,
                    numeroDeAsientos: this.numeroDeAsientos,
                    zonaDeBoletos: this.zonaDeBoletos,
                    cupon: null,
                    metodoDeFacturacion: informacionComercialDelProducto.metodoDeFacturacion ?? 'visita',
                    group: groupId,
                    nombreDelGrupo: this.informacionDelProducto.titulo,
                    commercialTerms: descuento || comision,
                    commercialTermsType: tipo_comision,
                    // tipo: p.tipo
                    tipo: this.tipoDePaquete === 'paquete' ? 'paquetes' : this.tipoDePaquete
                }

                itemsQueSeAgregaranACarrito.push(carritoItem);
            })

            try {

                for (let i of itemsQueSeAgregaranACarrito) {
                    await this.checkoutToCart(i);
                }
                // const carritoUuid = await Promise.all(
                //     itemsQueSeAgregaranACarrito.map( async (i) => {
                //         return await this.checkoutToCart( i );
                //     })
                // )

                this.$toast.success(this.$t("default.NOTIFICATIONS.ITEMS_TO_CART"));
                this.$router.push("/carrito");
            } catch (err) {
                console.log(err);
                this.$toast.error(this.$t("default.NOTIFICATIONS.ITEMS_TO_CART_ERROR"));
            } finally {
                this.movingToCart = false;
            }
            // En caso de que se haya escogido una reserva con descuento comercial, se calcula, si no se asigna el valor de 0
            // $vm.descuentoTotal = $vm.descuentoComercial ? ($vm.descuentoComercial.valor / 100 * ($vm.total)) * (1 + $vm.iva) : 0

            // // Creamos el identificador único de esta reserva
            // $vm.itemUuid = uuid.v4()

            // let comisionComercial = null;
            // let descuentoComercial = null;

            // if ($vm.tipoCobro == true) {
            //     comisionComercial = $vm.comision;
            // }
            // else {
            //     descuentoComercial = null;
            // }

            // this.consultandoDisponibilidad = true;

            // if (this.seats) {
            //     const check = await this.verificarAsientosDisponibles();
            //     this.consultandoDisponibilidad = false;
            //     if (check === false) {
            //         this.asientosOcupadosAlert = true;
            //         return;
            //     }
            // }

            // let itemId = null

            // Está condición sirve para interactuar con clorian, si la url contiene el nombre del monumento, entonces, se trata de una url relacionada con clorian
            // itemId = $vm.horarioSelected._id

            // let carritoItem = {
            //     itemID: itemId,
            //     itemClorianID: null,
            //     // TODO: MODIFICAR POR PRODUCTO CONTENIDO EN EL PAQUETE
            //     itemIDVisita: $vm.$route.params.entradaId,
            //     itemUuid: $vm.itemUuid,
            //     itemTarifaId: this.tarifaId,
            //     itemComisionTotal: $vm.comisionTotal,
            //     itemDescuentoTotal: $vm.descuentoTotal,
            //     itemBaseTotal: $vm.baseTotal,
            //     itemHorario: $vm.horarioSelected.hora,
            //     itemFecha: moment($vm.selectedDate.setUTCHours(0)).tz('Europe/Madrid').startOf('d').toDate(),
            //     itemTotal: $vm.total,
            //     itemTickets: $vm.resumen,
            //     itemVisita: $vm.nombres,
            //     itemMonumentoId: this.monumentoId,
            //     itemMonumento: $vm.monumentoNombres,
            //     itemComision: $vm.comision,
            //     itemTipoCobro: $vm.tipoCobro,
            //     itemComisionPorcentaje: $vm.tipoComision,
            //     cancelable: $vm.cancelable,
            //     seguroCancelacion: false,
            //     montoCancelacion: 0,
            //     timestamp: new Date(),
            //     pos: $vm.pos,
            //     carritoUuid: null,
            //     reservationId: null,
            //     asientos: this.selectedSeats,
            //     numeroDeAsientos: this.numeroDeAsientos,
            //     zonaDeBoletos: this.zonaDeBoletos,
            //     cupon: null,
            //     metodoDeFacturacion: this.metodoDeFacturacion ?? 'visita',
            //     paqueteId: groupId
            // }

            // TODO: This code 
            // if (!$vm.verificarDuplicidad(carritoItem)) {

            //     await $vm.$store.dispatch('addToCart', carritoItem);

            //     carritoItem.carritoUuid = $vm.$store.getters.cartStatus.uuid;

            //     await $vm.consultarInfoParaReserva();
            //     // Esta variable hace que no se puedan agregar más entradas con estos mismos datos
            //     $vm.productoAgregado = true;
            //     // Esta entrada hace que el icono de carrito parpadeé para llamar la atención;
            //     $vm.itemAgregado = true;
            // }
            // else {
            //     $vm.carritoItemTemp = carritoItem;
            //     $vm.itemDuplicadoAlert = true;
            // }
            // $vm.$router.push('/carrito')
        },
        async checkoutToCart(carritoItem) {
            const cartUuid = this.$store.getters.getCartUuid;

            const preReserva = {
                uuid: carritoItem.itemUuid,
                pax: carritoItem.itemTickets
                    .map((item) => item.cantidad)
                    .reduce((acc, curr) => acc + curr, 0),
                eventoId: carritoItem.itemID,
                monumentoId: carritoItem.itemMonumentoId,
                eventoClorianId: carritoItem.itemClorianId,
                asientos: carritoItem.asientos,
                tipo: carritoItem.tipo ?? 'monumentos',
                group: carritoItem.group
            }


            try {
                if (!cartUuid) {
                    const payload = { preReservas: [{ ...preReserva }], status: false };
                    const uuidToRegister = uuidv4();
                    payload.uuid = uuidToRegister;
                    const { expireAt } = (await ApiService.createCarrito(payload)).data;
                    carritoItem.carritoUuid = uuidToRegister;
                    this.$store.commit('registerCart', { carritoItem, uuidToRegister, expireAt });
                } else {
                    const payload = { ...preReserva };
                    const { cartExp } = (await ApiService.updateCarrito(cartUuid, payload)).data;
                    carritoItem.carritoUuid = cartUuid;
                    this.$store.commit('addProductsToCart', { carritoItem, expireAt: cartExp });
                }
            } catch (err) {
                console.log(err);
                this.productoAgregado = false;
                this.itemAgregado = false;
                throw Error( err );
            }


            // await this.$store.dispatch('addToCart', carritoItem);
            // const asignedUuid = this.$store.getters.cartStatus.uuid;
            // Esta variable hace que no se puedan agregar más entradas con estos mismos datos
            this.productoAgregado = true;
            // Esta entrada hace que el icono de carrito parpadeé para llamar la atención;
            this.itemAgregado = true;
        },
        goToEntrada(evento) {
            let $vm = this;
            $vm.$router.push("/castillos/tarifas/" + $vm.monumentoNombres[0]?.texto + '/' + evento)
        },
        goToEntradas(evento) {
            let $vm = this;
            if ($vm.tipoDeEntrada == "Experiencia") {
                $vm.$router.push("/experiencias-exclusivas/" + $vm.monumentoNombres[0]?.texto)
            }
            else if ($vm.tipoDeEntrada == "Evento") {
                $vm.$router.push("/eventos-singulares/" + $vm.monumentoNombres[0]?.texto)
            }
            else {
                $vm.$router.push("/castillos/tarifas/" + $vm.monumentoNombres[0]?.texto)
            }
        },
        goToContact() {
            let $vm = this;
            $vm.$router.push({
                name: 'contactReserva',
                query: {
                    monumento: $vm.monumentoNombres[$vm.idiomaIndex]?.texto,
                    tipo: $vm.tipoDeEntrada,
                    visita: $vm.nombres[$vm.idiomaIndex]?.texto
                }
            })
        },
        masRutas() {
            let $vm = this;
            $vm.$router.push("/castillos/rutas/" + $vm.monumentoId)
        },
        // Este evento es disparado cuando se detecta un cambio de mes en el calendario.
        async newMonth(event) {

            if (this.cargandoDisponibilidad) {
                this.cargandoDisponibilidad = false;
                return;
            }

            try {
                this.cargandoDisponibilidad = true;
                let $vm = this;
                this.loading = true;
                let today = new Date();
                let fromDate = null;
                let mesActual = today.getMonth() + 1;
                let yearInCourse = today.getFullYear();
                let month = event.month;
                let year = event.year;

                // Si el mes es igual al mes en el que estamos, limitamos la consulta a la fecha actual.
                if (month == mesActual && year == yearInCourse) {
                    if (yearInCourse == year) {
                        fromDate = today;
                    }
                    else {
                        fromDate = new Date(year, month - 1, 1);
                    }
                }
                // Si es un mes superior al actual, pedimos la información desde el primer día del mes
                else {
                    fromDate = new Date(year, month - 1, 1);
                }

                let queryResult;
                if (this.informacionDelProducto.tipo === 'paquetes') {
                    queryResult = (await Promise.all(
                        this.informacionDelProducto.infoPaquete.map(async (producto) => {
                            // Si utilizamos la fecha de fromDate, se inhabilitaran los dias anteriores a la fecha de fromDate.
                            const fecha = moment().tz('Europe/Madrid').startOf('day').format("YYYY-MM-DDTHH:mm:ssZZ")

                            // const informacionComercial = (await this.consultarCondicionesComerciales(producto._id, producto.tipo));
                            const fechas = (await this.consultarFechas(producto._id, producto.tipo, fecha));

                            const response = { fechasDisponibles: fechas };
                            // return await this.consultarFechas( producto._id, producto.tipo, fecha ) ;
                            return ({ ...response });
                        })
                    ))
                } else {
                    queryResult = (await Promise.all(
                    this.informacionDelProducto.info.map(async (producto) => {
                        const fecha = moment(fromDate).tz('Europe/Madrid').startOf('day').format("YYYY-MM-DDTHH:mm:ssZZ")

                        // const informacionComercial = (await this.consultarCondicionesComerciales(producto._id, producto.tipo));
                        const fechas = (await this.consultarFechas(producto._id, producto.tipo, fecha));

                        const response = { fechasDisponibles: fechas };
                        // return await this.consultarFechas( producto._id, producto.tipo, fecha ) ;
                        return ({ ...response });
                    })
                ))
                }

                const tipoDeOperacion = this.$route.query.tipo;
                const fechasDisponibles = queryResult.map(r => r.fechasDisponibles);

                if (tipoDeOperacion === "paquete") {
                    const todosLosProductos = this.informacionDelProducto.infoPaquete.map(c => c._id);
                    const fechasComunes = this.encontrarFechasComunes(fechasDisponibles.map(d => d.fechas).reduce((acc, curr) => acc.concat(curr), []), todosLosProductos);

                    fechasComunes.sort((a, b) => {
                        return moment(a).diff(moment(b), 'days');
                    })
                    this.enabledDates = fechasComunes;
                }

                if (this.enabledDates.length) {
                    // const calendar = this.$refs['calendario'];
                    // calendar.focusDate( this.enabledDates[0] );
                    this.minDate = this.enabledDates[0];
                    this.visitaFirstDate = true;
                }
            } catch (err) {
                console.log(err);
            } finally {
                this.loading = false;
                this.cargandoDisponibilidad = false;
                this.selectedDate = null;
                this.infoForEnabledDates = {};
                this.seleccionesDelUsuario = {}
                this.preciosDisponiblesEnLaSeleccion = {};

                this.resetBoletos();
            }

            // await $vm.getFechas(fromDate)

        },
        getDisponiblidad() {

        },

        async consultarCondicionesComerciales(id, tipo) {
            try {
                const result = (await ApiService.consultarAcuerdoComercial(id, tipo)).data;
                return result;
            } catch (err) {
                console.log("No se pudo obtener la información de los acuerdos comerciales");
                return null;
            }
        },

        async consultarFechas(idProducto, productoTipo, fecha) {
            try {
                // if (productoTipo === 'monumentos') {
                //     const data = (await ApiService.getFechaByVisitaId(this.$i18n.locale ?? 'es', idProducto, fecha)).data;
                //     return ({ idProducto, tipo: productoTipo, fechas: [...data.fecha], visitaExist: { ...data.visitaExist }, visitaGenerales: { ...data.visitaGenerales } })
                // } else {
                //     const data = (await ApiService.consultarFechaDeServicios(this.$i18n.locale ?? 'es', idProducto, fecha, productoTipo)).data;
                //     return ({ idProducto, tipo: productoTipo, fechas: [...data.fecha], visitaExist: { ...data.visitaExist }, visitaGenerales: { ...data.visitaGenerales } })
                // }
                const data = (await ApiService.consultarFechaDeServicios(this.$i18n.locale ?? 'es', idProducto, fecha, productoTipo)).data;
                return ({ idProducto, tipo: productoTipo, fechas: [...data.fecha], visitaExist: { ...data.visitaExist }, visitaGenerales: { ...data.visitaGenerales } })
            } catch (err) {
                console.log(err);
                return ({ idProducto, tipo: productoTipo, fechas: [], visitaExist: null, visitaGenerales: null });
            }
        },

        // Obtenemos las fechas disponibles para el mes con el que se está trabajando
        async getFechas(fromDate, autoFocus = true) {
            this.loading = false;
            // let fromDateStandard = moment(fromDate).format('YYYY-MM-DD');

            // fromDateStandard += 'T00:00:00.000Z'
            // let $vm = this;

            // $vm.loading = true;

            // $vm.enabledDates = [];
            // $vm.precios = [];

            // try {

            //     // Hacemos la consulta al servidor, le pasamos como datos el idioma, el id de la entrada que queremos conocer y
            //     // la fecha a partir de la que se quiere consultar, nos va a devolver los valores para dos meses seguidos
            //     const result = await ApiService.getFechaByVisitaId($vm.$i18n.locale, $vm.entradaId, fromDateStandard);
            //     $vm.loading = false
            //     // La información de dispoinibilidad se utiliza para determinar que fechas se van a marcar como disponibles en el calendario
            //     $vm.disponibilidadInfo = [...result.data.fecha];

            //     $vm.disponibilidadInfo = $vm.disponibilidadInfo.sort(function (a, b) {
            //         return new Date(a.fecha) - new Date(b.fecha);
            //     })
            //     // $vm.visitaFirstDate = result.data.visitaExist;
            //     $vm.visitaFirstDate = result.data.visitaExist;

            //     let tempHorariosInfo = null;

            //     let now = moment();

            //     $vm.disponibilidadInfo.forEach(element => {
            //         let dateObject = { 'start': element.fecha, 'end': element.fecha };
            //         let horariosDisponibles = []

            //         // let now = moment();
            //         horariosDisponibles = element.horarios.filter(horario => {
            //             if (horario.pausado) {
            //                 return false
            //             }
            //             else if (horario.horaDeCierre) {
            //                 if (moment.utc(horario.horaDeCierre).isBefore(now)) {
            //                     return false
            //                 }
            //                 else {
            //                     return true;
            //                 }
            //             }
            //             else {
            //                 return true;
            //             }
            //         })

            //         if (horariosDisponibles.length > 0) {
            //             $vm.enabledDates.push(dateObject);
            //         }
            //     });

            //     // El tipo de entrada se muestra en la barra de resultados
            //     $vm.tipoDeEntrada = $vm.disponibilidadInfo[0] ? $vm.disponibilidadInfo[0].tipo : $vm.tipoDeEntrada;

            //     let todayIsOk = true;
            //     // Si es una entrada abierta, la hora de visita puede ser cualquiera y solo hay una
            //     // hora en la información devuelta, por lo que se fija la tarifa disponible de manera automática
            //     if ($vm.disponibilidadInfo[0]) {
            //         if ($vm.disponibilidadInfo[0].horarios.length == 1 && $vm.disponibilidadInfo[0].horarios[0].hora == null) {
            //             let now = moment();
            //             const lastHour = $vm.disponibilidadInfo[0].horarios.length - 1
            //             const horario = $vm.disponibilidadInfo[0].horarios[lastHour];
            //             if (horario.horaDeCierre) {
            //                 if (moment.utc(horario.horaDeCierre).isAfter(now)) {
            //                     //   $vm.setTarifa($vm.disponibilidadInfo[0].horarios[0]);
            //                     todayIsOk = true;
            //                 }
            //                 else {
            //                     todayIsOk = false;
            //                 }
            //             }
            //         }
            //     }
            //     const calendar = $vm.$refs['calendario'];

            //     let fecha = null;

            //     if (todayIsOk) {
            //         fecha = $vm.disponibilidadInfo[0] ? $vm.disponibilidadInfo[0].fecha : null;
            //         tempHorariosInfo = $vm.disponibilidadInfo[0] ? [...$vm.disponibilidadInfo[0].horarios] : [];
            //     }
            //     else {
            //         fecha = $vm.disponibilidadInfo[1] ? $vm.disponibilidadInfo[1].fecha : null;
            //         tempHorariosInfo = $vm.disponibilidadInfo[1] ? [...$vm.disponibilidadInfo[1].horarios] : [];
            //     }

            //     $vm.horariosInfo = tempHorariosInfo.filter(hora => {
            //         if (hora.horaDeCierre) {
            //             if (moment.utc(hora.horaDeCierre).isBefore(now)) {
            //                 return false
            //             }
            //             else {
            //                 return true;
            //             }
            //         }
            //         else if (hora.hora) {
            //             let date = moment.utc($vm.disponibilidadInfo[0].fecha).format("YYYY-MM-DD");

            //             let horario = moment.utc(hora.hora).format("HH:mm:ss");
            //             let newHour = moment(date + "T" + horario)

            //             if (now.format("YYYY-MM-DD") === date) {
            //                 var isAfter = moment(newHour).isAfter(now);
            //                 return isAfter;

            //             }
            //             else {
            //                 return true
            //             }
            //         }
            //         else {
            //             return true;
            //         }
            //     })

            //     $vm.horariosInfo = $vm.horariosInfo.map(hora => {
            //         let plazasReservadas = hora.plazasReservadas != null && hora.plazasReservadas >= 0 ? hora.plazasReservadas : 0;
            //         if (hora.plazas > 0) {
            //             if (hora.plazas <= (hora.plazasOcupadas + plazasReservadas)) {
            //                 hora.pausado = true
            //             }
            //         }
            //         else {
            //             hora.pausado = false
            //         }

            //         return hora;
            //     })

            //     $vm.selectedDate = fecha ? new Date(fecha) : null;

            //     if (fecha) {
            //         calendar.focusDate(new Date(fecha));
            //     }

            //     this.selectedDate = null;
            //     $vm.cargandoDisponibilidad = false;
            // }
            // catch (err) {
            //     this.loading = false;
            //     console.log(err);
            // }
        },

        resetBoletos() {
            try {
                this.$store.commit('resetBoletos')
                // this.$refs['botoneras'].reset();
                EventBus.$emit("resetBoletos");
            }
            catch (err) {
                console.log(err);
            }
        },
        // Calculamos los precios que se mostrarán en la siguiente etapa de reservas
        // Se debe tomar en cuenta la información comercial
        procesarPreciosDisponibles(preciosDelHorario, productoId) {
            const ultimosPreciosDisponibles = { ...this.preciosDisponiblesEnLaSeleccion };
            this.preciosDisponiblesEnLaSeleccion = {};

            const preciosInfo = preciosDelHorario.filter(p => p.web === true || p.paquetes === true).map(p => {
                const tipoDeCondicionComercial = this.informacionComercial[productoId].tipo;
                const valorCondicionComercial = this.informacionComercial[productoId].valor;
                const autocompensado = this.informacionComercial[productoId].autocompensar;
                const precioBase = p.precioPaquete ?? p.precio;
                const costoServicio = p.costoDelPaquete ?? 0;
                let pvp = p.precioPaquete ?? p.precio;
                let mensajeDeComision = "";
                if (tipoDeCondicionComercial === 3 || tipoDeCondicionComercial === 1) {
                    if (tipoDeCondicionComercial === 3) {
                        pvp += Number(((p.precio * valorCondicionComercial / 100) * 1.21).toFixed(2));
                        mensajeDeComision = ((valorCondicionComercial / 100)).toLocaleString('es-ES', { style: 'percent', maximumFractionDigits: 2, minimumFractionDigits: 2 });
                    } else {
                        pvp += Number((valorCondicionComercial * 1.21).toFixed(2));
                        mensajeDeComision = valorCondicionComercial.toLocaleString('es-ES', { style: 'currency', currency: 'EUR' })
                    }
                }
                // const noChargeForFreeTickets = this.informacionComercial[productoId].noChargeForFreeTickets;
                const noChargeForFreeTickets = true;
                return ({
                    ...p,
                    precioBase,
                    pvp,
                    tipoDeCondicionComercial,
                    valorCondicionComercial,
                    autocompensado,
                    noChargeForFreeTickets,
                    mensajeDeComision
                });
            })

            if (!ultimosPreciosDisponibles[productoId]) {
                ultimosPreciosDisponibles[productoId] = [];
            }
            ultimosPreciosDisponibles[productoId] = [...preciosInfo].sort((a, b) => ((a.orden ?? 0) - (b.orden ?? 0)));

            this.preciosDisponiblesEnLaSeleccion = ultimosPreciosDisponibles;
        },
        seleccionarHorario(horarioId, productoId) {

            const tempDate = { ...this.seleccionesDelUsuario };
            this.seleccionesDelUsuario = {};
            if (!tempDate[productoId]) {
                tempDate[productoId] = {
                    horario: {

                    },
                    tarifas: null
                };
            }
            tempDate[productoId].horario = this.infoForEnabledDates[productoId].find(h => h._id === horarioId);
            this.seleccionesDelUsuario = tempDate;

            let limitarNumeroDeTickets = false;
            let maximoDePlazas = null;
            for (const [key, value] of Object.entries(tempDate)) {
                if (value.horario.plazas) {
                    limitarNumeroDeTickets = true;
                    let plazasOcupadas = 0;
                    let plazasReservadas = 0;
                    if (value.horario.plazasReservadas < 0 && value.horario.plazasReservadas !== null) {
                        value.horario.plazasReservadas = 0;
                    }
                    if (value.horario.plazasOcupadas < 0 && value.horario.plazasOcupadas !== null) {
                        value.horario.plazasOcupadas = 0;
                    }

                    plazasOcupadas = value.horario.plazasOcupadas;
                    plazasReservadas = value.horario.plazasReservadas;
                    const plazasDisponibles = value.horario.plazas - plazasReservadas - plazasOcupadas;
                    if (maximoDePlazas === null) {
                        maximoDePlazas = plazasDisponibles;
                    } else {
                        if (maximoDePlazas > plazasDisponibles) {
                            if (plazasDisponibles >= 0) {
                                maximoDePlazas = plazasDisponibles;
                            } else {
                                maximoDePlazas = 0;
                            }
                        }
                    }
                }

                if( this.informacionDelProducto.opts.diasDiferidos ){
                    this.dataStepper.forEach( d => {
                        if( d.producto === productoId ){
                            this.horarioSelected = this.seleccionesDelUsuario[ productoId].horario?._id
                        }
                    })
                }

                this.procesarPreciosDisponibles(value.horario?.precios, key);
            }
            this.resetBoletos();
            this.$store.commit('setTicketsLimit', { limitar: limitarNumeroDeTickets, nuevoNumeroDePlazas: maximoDePlazas });

        },
        // TODO: Remover la lógica de aquí
        async setTarifa(horario) {
            let $vm = this;

            this.resetBoletos();
            $vm.loading = false;
            $vm.horarioSelected = horario;


            const plazasDisponibles = this.getPlazasDisponibles(horario.plazas, horario.plazasOcupadas, horario.plazasReservadas);

            if (horario.configuracionAsientos) {

                const result = await ApiService.getZonasConfig(horario.configuracionAsientos);
                this.zonasInfo = [];

                const zonas = result.data.zonas;

                this.zonasInfo = zonas.map(z => {

                    const asientosOcupados = z.asientosOcupados ?? 0;
                    const asientosReservados = z.asientosReservados ?? 0;
                    const obj = {
                        _id: z._id,
                        name: z.nombre,
                        color: z.color,
                        filas: z.filas,
                        orden: z.orden,
                        asientos: (z.asientos - asientosOcupados - asientosReservados)
                    }
                    return obj;
                });


                this.zonasInfo.sort(function (a, b) {
                    const orden1 = a.orden ?? 0;
                    const orden2 = b.orden ?? 0;
                    return a.orden - b.orden;
                })
                // const imagenIlustrativa = result.data.imagen;

                this.imagenIlustrativa = result.data.imagen;
                this.originLayout = result.data.plantillaId;

                this.visibleSeats = false;
            }

            if (plazasDisponibles === 'Sin límite') {
                this.$store.commit('guardarPlazas', null);
            }
            else {
                this.$store.commit('guardarPlazas', plazasDisponibles);

            }

            this.visibleHours = false;

            $vm.ticketsVisible = true;

            let nombreDeMonumentoQuery = $vm.$route.query.monumento;

            this.precios = [];

            // Esto quiere decir que el monumento está configurado para funcionar con clorian
            if (nombreDeMonumentoQuery) {

                let preciosTemp = $vm.clorianRatesInfo;

                $vm.precios = preciosTemp.map(precio => {
                    return {
                        descripciones: [{ texto: precio.shortName }, { texto: precio.shortName }, { texto: precio.shortName }, { texto: precio.shortName }],
                        buyerTypeId: precio.buyerTypeId,
                        precio: parseFloat(precio.price),
                    }
                })

                $vm.selected = [];

                $vm.precios.forEach(item => {
                    let precio = item.precio;
                    let comision = null;
                    let descuento = null;

                    if ($vm.tipoCobro) {
                        if (precio > 0) {
                            comision = $vm.comision;
                        }
                        else {
                            if (this.noChargeForFreeTickets) {
                                comision = 0;
                            }
                            else {
                                comision = this.comision;
                            }
                        }
                    }
                    else {
                        if (precio > 0) {
                            descuento = $vm.comision
                        }
                        else {
                            if (this.noChargeForFreeTickets) {
                                descuento = 0;
                            }
                            else {
                                descuento = this.comision;
                            }
                        }
                    }
                    this.cantidades.push(0)
                    $vm.selected.push({
                        'cantidad': 0, buyerTypeId: item.buyerTypeId,
                        descripcion: item.descripciones[$vm.idiomaIndex].texto, tipo_comision: $vm.tipoComision, comision: comision,
                        descuento: descuento, precio: precio, total: 0, pvp: item.precio, minPersonas: 1
                    });

                })

            }

            else {

                this.tarifaId = horario.tarifa._id;
                let preciosTemp = [...horario.tarifa.precios]

                const idsPrecios = preciosTemp.map(p => p.oldId);

                let preciosModificados = [];
                try {
                    const preciosModificadosResult = await ApiService.getModificadoresDePrecios(idsPrecios);

                    preciosModificados = preciosModificadosResult.data;
                }
                catch (err) {
                    console.log(err);
                }

                $vm.precios = preciosTemp.filter(precio => {
                    if ((precio.activo == null || precio.activo == true) && (precio.web == null || precio.web == true)) {
                        return true;
                    }
                    else {
                        return false;
                    }
                })

                if (preciosModificados.length) {

                    $vm.precios.forEach(p => {

                        let modificaciones = preciosModificados.filter(pm => pm.idPrecio == p.oldId);

                        let modificacionesPorFecha = modificaciones.filter(mp => mp.fechaDeModificador != null);

                        if (modificacionesPorFecha.length > 0) {
                            modificacionesPorFecha.forEach(mp => {
                                const fechaDeDisparo = moment(mp.fechaDeModificador);
                                // const fechaSeleccionada = moment(this.selectedDate).utc().format('YYYY-MM-DD') 
                                const today = moment();

                                if (fechaDeDisparo.isSameOrBefore(today)) {
                                    p.precio = mp.valor;
                                }
                            })
                        }
                    })
                }

                $vm.selected = []

                $vm.precios.sort((a, b) => {
                    return a.orden - b.orden;
                })

                $vm.precios.forEach(item => {
                    let pvp = $vm.priceCalculator(item.precio);
                    let comision = null;
                    let descuento = null;

                    this.cantidades.push(0)

                    if ($vm.tipoCobro) {
                        comision = $vm.comision;
                    }
                    else {
                        descuento = $vm.comision
                    }

                    comision = $vm.condicionComercial ? $vm.condicionComercial.valor : null;

                    if (this.monumentoNombres[0].texto === "Castillo de Consuegra") {
                        comision = pvp > 0 ? comision : 0;
                    }

                    descuento = $vm.descuentoComercial ? $vm.descuentoComercial.valor : null;
                    $vm.selected.push({
                        'cantidad': 0, descripcion: item.descripciones[$vm.idiomaIndex].texto,
                        tipo_comision: $vm.tipoComision, comision: comision, descuento: descuento, precio: item.precio,
                        total: 0, pvp: pvp, minPersonas: item.minPersonas
                    })
                })

            }

            $vm.loading = false;

            return;

        },
        priceCalculator(precio) {
            let $vm = this;

            if ($vm.condicionComercial) {
                if ($vm.condicionComercial.tipo == 1) {

                    if (precio > 0) {
                        return precio + ($vm.condicionComercial.valor * (1 + $vm.iva));
                    }
                    else {
                        if (this.monumentoNombres[0].texto === "Castillo de Consuegra") {
                            return precio;
                        }
                        else {
                            if (this.noChargeForFreeTickets == true) {
                                return precio;
                            }
                            else {
                                return precio + ($vm.condicionComercial.valor * (1 + $vm.iva));
                            }
                        }
                    }
                }
                else {
                    let porcentajeExtra = precio * ($vm.condicionComercial.valor / 100);
                    return precio + ((porcentajeExtra) * (1 + $vm.iva))
                }
            }
            else {
                return precio;
            }
        },
        cancelarOperacion() {
            let $vm = this;
            $vm.itemDuplicadoAlert = false;
            $vm.carritoItemTemp = null;
        },
        duplicarItem() {
            let $vm = this;

            $vm.itemDuplicadoAlert = false;

            $vm.$store.commit('addToCart', $vm.carritoItemTemp);

            // Esta variable hace que no se puedan agregar más entradas con estos mismos datos
            $vm.productoAgregado = true;
            // Esta entrada hace que el icono de carrito parpadeé para llamar la atención;
            $vm.itemAgregado = true;

            var element = this.$refs['checkout'];
            var top = element.offsetTop;

            $vm.carritoItemTemp = null;
            // document.getElementById('resumen-general').scrollIntoView({ behavior:'auto', block:'start', inline:"start" })

        },
        async getPricingForEvent(productId, date) {
            let $vm = this;
            ClorianService.getPricingForEvent(productId, date, $vm.monumentoNombres[0].texto)
                .then(res => {
                    console.log(res)
                })
                .catch(err => {
                    console.log(err);
                })

        },

        async daySelected(day, productoId = '', _index = 0) {
            let $vm = this;

            let _selectedDate = '';

            const diasDiferidos = this.informacionDelProducto.opts?.diasDiferidos;
            if ( diasDiferidos) {
                if (!this.enabledDatesByProducts[productoId].includes(`${day.id}T00:00:00.000Z`)) {
                    return;
                }
                _selectedDate = this.dataStepper.find(d => d.producto === d.productoId)?.fecha;
            } else {
                if (!this.enabledDates.includes(`${day.id}T00:00:00.000Z`)) {
                    return;
                }
                _selectedDate = this.selectedDate;
            }

            if (_selectedDate === null) {
                this.precios = [];
                this.visibleHours = false;
                this.visibleDate = true;
                this.loadingSchedule = false;
                this.horarioSelected = null;
                this.ticketsVisible = false;
                $vm.selected = []
                $vm.precios = [];
                this.resumen = [];
                this.visibleCheckout = false;

                if( this.productContainer.length > 1 ){

                    if( this.infoForEnabledDates[productoId] ){
                        delete this.infoForEnabledDates[productoId];
                    }
                    if( this.seleccionesDelUsuario[productoId] ){
                        delete this.seleccionesDelUsuario[productoId];
                    }

                // this.infoForEnabledDates = {};
                }else{
                    this.infoForEnabledDates = {};
                    this.seleccionesDelUsuario = {}
                }

                // this.infoForEnabledDates = {};
                this.preciosDisponiblesEnLaSeleccion = {};
                this.resetBoletos();
            }
            else {
                if( this.productContainer.length > 1 ){

                    if( this.infoForEnabledDates[productoId] ){
                        delete this.infoForEnabledDates[productoId];
                    }
                    if( this.seleccionesDelUsuario[productoId] ){
                        delete this.seleccionesDelUsuario[productoId];
                    }
                    // this.infoForEnabledDates = {};
                }else{
                    this.infoForEnabledDates = {};
                    this.seleccionesDelUsuario = {}
                }
                this.loadingSchedule = true;
                // const daySelected = day;
                this.preciosDisponiblesEnLaSeleccion = {};
                const queryDate = moment(day.id).format("YYYY-MM-DDT00:00:00+00:00");
                const obj = {
                    ...this.infoForEnabledDates
                };
                try {
                    let _productContainer = []
                    if( diasDiferidos ){
                        _productContainer = this.productContainer.filter( p => p._id === productoId );
                    }else{
                        _productContainer = [...this.productContainer ];
                    }
                    const horariosDisponibles = await Promise.all(
                        /**To check info about the products in the pack, change infoPaquete to info */
                        _productContainer.map(async p => {
                            try {
                                const result = await ApiService.consultarHorarios("es", p._id, queryDate, queryDate, p.tipo);
                                if (!obj[p._id]) {
                                    obj[p._id] = result.map(r => {

                                        const label = r.hora ? moment(r.hora).utc().format("HH:mm") : this.$t("default.FREE_SCHEDULE_LABEL")
                                        let plazasDisponibles = 0;

                                        if( r.plazas ){
                                            plazasDisponibles = (r.plazas - r.plazasOcupadas - r.plazasReservadas);
                                        }
                                        let plazasDisponiblesLabel = !r.plazas ? '-' : plazasDisponibles;
                                        const _obj = { ...r, label: label, plazasDisponibles, plazasDisponiblesLabel }
                                        return _obj;
                                    });
                                }
                                return result;
                            } catch (err) {
                                console.log( err );
                                obj[p._id] = [];
                                return []
                            }
                        })
                    )
                    if (horariosDisponibles.length === 0) {
                        this.$toast.warning(this.$t("default.NOTIFICATIONS.NO_AVAILABLE_DATES"));
                    }
                    this.infoForEnabledDates = { ...obj };

                    /**To check info abour the products, change infoPaquete to info */
                    _productContainer.forEach(p => {
                        if (obj[p._id].length === 1) {
                            if (obj[p._id][0].hora === null) {
                                this.seleccionarHorario(obj[p._id][0]._id, p._id)
                            }
                        }
                    })

                } catch (err) {
                    console.log(err);
                    this.$toast.error(this.$t("default.NOTIFICATIONS.SCHEDULE_QUERY_ERROR"));
                } finally {
                    this.loadingSchedule = false;
                }
                //   this.$store.commit('resetBoletos')
                this.resetBoletos();
                this.visibleDate = false;
                this.visibleHours = true;

                if( diasDiferidos ){
                    this.dataStepper[_index].visibleDate = this.visibleDate;
                    this.dataStepper[_index].visibleHours = this.visibleHours;
                }
                return;
            }
        },

        async obtenerPaquete(id, tipoDePaquete = "") {
            const language = this.$store.getters.getIdioma;
            const res = await ApiService.obtenerPaquete(id, tipoDePaquete, language );
            return res.data;
        },

        encontrarFechasComunes(fechas, productos) {

            const todasLasFechas = new Set();

            fechas.forEach(f => todasLasFechas.add(f.fecha));

            const productosEnCadaFecha = Object.assign(...Array.from(todasLasFechas, fecha => ({ [fecha]: [] })))

            fechas.forEach(obj => {

                productosEnCadaFecha[obj.fecha].push(obj.servicio || obj.visita || obj.paqueteId);

                // const fechaStr = obj.fecha.split("T")[0];
                // if( fechaCounts.has( fechaStr) ){
                //     fechaCounts.set( fechaStr, fechaCounts.get( fechaStr ) + 1 );
                // }else{
                //     fechaCounts.set( fechaStr, 1 );
                // }
            });

            const fechasComunes = [];
            todasLasFechas.forEach(f => {
                const checker = productos.every(v => productosEnCadaFecha[f].includes(v));
                if (checker) {
                    fechasComunes.push(f);
                }

            })
            // const fechasComunes = productosEnCadaFecha.filter( p => {
            // } ) ;

            // fechaCounts.forEach( (count, fechaStr) => {
            //     if( count == totalObjects ){
            //         fechasComunes.push( fechaStr );
            //     }
            // })

            return fechasComunes;
        },
        async fillPackageData(tipo, productos, fecha, restricciones) {
            const _fechas = [];
            const _informacionComercial = {};

            const queryResult = (await Promise.all(
                productos.map(async (producto) => {

                    const fechasDisponibles = (await this.consultarFechas(producto._id, producto.tipo, fecha));
                    const informacionComercial = (await this.consultarCondicionesComerciales(producto._id, producto.tipo));
                    const response = { fechas: { id: producto._id, fechasDisponibles }, datosComerciales: informacionComercial }
                    return ({ ...response });
                })
            ));

            const todosLosProductos = productos.map(c => c._id);
            const fechasDisponibles = queryResult.map(r => r.fechas);

            const todasLasFechasMerge = fechasDisponibles.map(d => d.fechasDisponibles).map(d => d.fechas).reduce((acc, curr) => acc.concat(curr), []);
            const fechasComunes = this.encontrarFechasComunes(todasLasFechasMerge, todosLosProductos);
            const tempDatosComerciales = queryResult.map(r => r.datosComerciales);
            // const datosComerciales = {};

            tempDatosComerciales.forEach(d => {
                _informacionComercial[d.productId] = { ...d };
            })

            fechasComunes.sort((a, b) => {
                return moment(a).diff(moment(b), 'days');
            })

            return { _informacionComercial, _fechasComunes: fechasComunes, _todasLasFechas: fechasDisponibles }
        }
    },
    setup() {

    },
    computed: {
        horarioParaResumen() {
            const hora = this.horarioSelected ? this.horarioSelected.hora ? new Date(this.horarioSelected.hora).toLocaleTimeString('es-ES', { 'timeZone': 'UTC', hour: '2-digit', minute: '2-digit' }) : this.$t("default.FREE_SCHEDULE_LABEL") : ""
            return hora;
        },

        getTicketsAmount() {
            let personas = this.selected.map(item => item.cantidad).reduce((acc, curr) => acc + curr, 0)
            return personas;
        },
        horarios() {
            let horariosSorted = this.horariosInfo;
            horariosSorted.sort(function (a, b) {
                return new Date(a.hora) - new Date(b.hora)
            })
            return horariosSorted;
        },
        horariosLimitados() {
            if (this.horariosInfo) {
                if (this.horariosInfo.length) {
                    if (this.horariosInfo[0].hora == null) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return true;
                }
            }
            else {
                return true;
            }
            // return false
        },
        selectedIdiomaIndex() {
            let $vm = this;
            let idiomaIndex = 0;
            if ($vm.$i18n.locale == 'es') {
                idiomaIndex = 0;
            }
            else if ($vm.$i18n.locale == 'en') {
                idiomaIndex = 1;
            }
            else if ($vm.$i18n.locale == 'fr') {
                idiomaIndex = 2;
            }
            else if ($vm.$i18n.locale == 'de') {
                idiomaIndex = 3;
            }
            return idiomaIndex;
        },
        selectedIdioma() {
            let $vm = this;

            return $vm.$i18n.locale ?? 'es';
        },
        habilitarPasoACarrito() {
            const personas = this.selected.map(item => item.cantidad).reduce((acc, curr) => acc + curr, 0)

            if ((this.total ?? 0) > 0 || personas > 0 && this.consultandoDisponibilidad === false) {
                return true;
            } else {
                return false;
            }
        },
        permitirControlDeSeleccionDePrecios() {
            /**To lookup for pack content, change infoPaquete to info */
            // const totalDeProductos = this.informacionDelProducto.infoPaquete.length;
            const totalDeProductos = this.productContainer.length;

            if (totalDeProductos === 0) {
                return false;
            } else {
                const cantidadDeHorariosSeleccionados = Object.keys(this.seleccionesDelUsuario).length;
                if (cantidadDeHorariosSeleccionados === totalDeProductos && this.movingToCart === false ) {
                    return true;
                }
            }
            return false;
        }
    },
    async created() {
        try {
            let $vm = this;
            this.resetBoletos();
            const paqueteDeMonumentos = this.$route.path.includes("castillos");
            this.consultarBienvenida = this.$route.query?.direct ?? false;
            $vm.cargandoDisponibilidad = true;

            const tipoDeOperacion = this.$route.query.tipo;
            const productoId = this.$route.query.id;

            this.tipoDePaquete = tipoDeOperacion;
            // Obtenemos la información del paquete
            if (tipoDeOperacion) {
                const result = await this.obtenerPaquete(productoId, tipoDeOperacion);
                if( result.pausarVenta === true ){
                    this.informacionDelProducto.pausado = result.pausarVenta;
                    throw Error("Venta pausada");
                }
                this.informacionDelProducto.titulo = result.webName || result.nombres?.[0]?.texto || result.name;
                this.siteData.webName = result.webName;
                this.siteData.monumentName = result.monumentName;
                this.siteData.pageTitle = result.webName || result.name;
                if (tipoDeOperacion === 'paquete') {
                    this.informacionDelProducto.tipo = "paquetes";
                    this.informacionDelProducto.diasDiferidos = false;
                    this.informacionDelProducto.ownerId = result.promotorId?._id;
                    this.informacionDelProducto.ownerName = result.promotorId?.nombreComercial;
                    this.informacionDelProducto._id = productoId;
                } else if (tipoDeOperacion === 'monumentos') {
                    this.informacionDelProducto.tipo = 'monumentos';
                    this.informacionDelProducto.ownerId = result.monumentoId?._id;
                    this.informacionDelProducto.ownerName = result.monumentoId?.nombre;
                    this.informacionDelProducto.opts.diasDiferidos = result.diasDiferidos ?? false;
                    this.informacionDelProducto._id = productoId;
                } else {
                    this.informacionDelProducto.tipo = tipoDeOperacion;
                    this.informacionDelProducto.ownerId = result.promotorId?._id;
                    this.informacionDelProducto.ownerName = result.promotorId?.nombreComercial;
                    this.informacionDelProducto.diasDiferidos = false;
                    this.informacionDelProducto._id = productoId;
                }

                if( result.tarifa && tipoDeOperacion === 'monumentos' ){
                    try{
                        const _tarifasResult = await ApiService.obtenerTarifasDelPaquete( result._id, result.tarifa, tipoDeOperacion );
                        this.informacionDelProducto.tarifaId = result.tarifa;
                        this.preciosEnPaquete = _tarifasResult?.precios ?? [];

                        if( this.preciosEnPaquete.length > 0 ){
                            this.permitirSeleccionDiferida = false;
                        }
                    }catch( err ){
                        console.log( err );
                    }
                }
                this.pageTitle = this.informacionDelProducto.titulo;

                this.informacionDelProducto.info = result.contenido.map(c => {
                    let ownerName = "";
                    if (c.castilloNombres) {
                        ownerName = c.castilloNombres[0].texto;
                    }else {
                        ownerName = c.proveedor;
                    }

                    let nombre = "";

                    if (c.nombres) {
                        nombre = c.nombres[0].texto;
                    } else {
                        nombre = c.nombre;
                    }
                    const obj = {
                        _id: c._id || c.visitaId || c.servicioId,
                        nombre: nombre,
                        tipo: c.monumentoId ? 'monumentos' : 'servicios',
                        ownerId: c.monumentoId ?? c.prestadorId,
                        mostrarAforo: c.mostrarAforo,
                        aforo : c.aforo,
                        ownerName: ownerName,
                        precio: c.precio
                    };
                    return obj;
                });
                this.informacionDelProducto.infoPaquete = [
                    {
                        _id: productoId,
                        nombre: this.informacionDelProducto.titulo,
                        tipo: this.informacionDelProducto.tipo,
                        ownerId: this.informacionDelProducto.ownerId,
                        ownerName: this.informacionDelProducto.ownerName
                    }
                ]

                if (this.informacionDelProducto.tipo === 'monumentos') {
                    this.productContainer = [...this.informacionDelProducto.info];
                } else if (this.informacionDelProducto.tipo === 'paquetes') {
                    this.productContainer = [...this.informacionDelProducto.infoPaquete];
                }

                this.dataStepper = this.productContainer.map((p, index) => {
                    return ({ 
                        producto: p._id, 
                        status: false, 
                        step: index, 
                        fecha: null, loading: false,
                        visibleDate: !index ? true : false,
                        selectedDeparture: null,
                        visibleHours: false,
                        visiblePrices: false,
                        selectedPrices: new Set()
                    })
                })
            }

            const fecha = moment().tz('Europe/Madrid').startOf('day').format("YYYY-MM-DDTHH:mm:ssZZ")

            // if( this.tipoDePaquete === 'monumentos' ){

            // }else{

            // }
            /**Aplicar este método para buscar por servicio y monumento al mismo tiempo */
            // const queryResult = (await Promise.all(
            //     this.informacionDelProducto.info.map(async (producto) => {

            //         const informacionComercial = (await this.consultarCondicionesComerciales(producto._id, producto.tipo));
            //         const fechas = (await this.consultarFechas(producto._id, producto.tipo, fecha));

            //         const response = { fechasDisponibles: fechas, datosComerciales: informacionComercial }
            //         // return await this.consultarFechas( producto._id, producto.tipo, fecha ) ;
            //         return ({ ...response });
            //     })
            // ))
            // const fechasDisponibles = queryResult.map(r => r.fechasDisponibles);
            // const tempDatosComerciales = queryResult.map(r => r.datosComerciales);
            // const datosComerciales = {};

            // tempDatosComerciales.forEach(d => {
            //     datosComerciales[d.productId] = { ...d };
            // })

            const productos = [];

            if (this.tipoDePaquete === 'paquete') {
                productos.push({ _id: productoId, tipo: 'paquetes' });
            } else if (this.tipoDePaquete === 'monumentos') {
                this.informacionDelProducto.info.forEach(i => {
                    productos.push({ _id: i._id, tipo: 'monumentos' });
                })
            }
            const result = await this.fillPackageData(this.tipoDePaquete, productos, fecha, {})
            // const fechasDisponibles = (await this.consultarFechas(productoId, 'paquetes', fecha)).fechas;
            // const datosComerciales = await this.consultarCondicionesComerciales(productoId, 'paquetes');

            // this.informacionComercial = { [productoId]: { ...datosComerciales } };
            // if (tipoDeOperacion === "servicios") {
            //     const todosLosProductos = this.informacionDelProducto.info.map(c => c._id);
            //     const fechasComunes = this.encontrarFechasComunes(fechasDisponibles.map(d => d.fechas).reduce((acc, curr) => acc.concat(curr), []), todosLosProductos);

            //     fechasComunes.sort((a, b) => {
            //         return moment(a).diff(moment(b), 'days');
            //     })
            //     this.enabledDates = fechasComunes;
            // }

            /** Comment to to lookup by common dates in calendar for multiple products*/
            // const fechasComunes = fechasDisponibles.map(f => f.fecha);
            // fechasComunes.sort((a, b) => {
            //     return moment(a).diff(moment(b), 'days');
            // })
            /*************************************************************************/
            this.enabledDates = result._fechasComunes;
            this.informacionComercial = result._informacionComercial;

            if( this.tipoDePaquete === 'monumentos' ){
                const informacionComercialDelPaquete = (await this.consultarCondicionesComerciales( productoId, 'paquetes_monumentos' ));

                this.informacionComercial[ productoId ] = informacionComercialDelPaquete;
            }
            this.enabledDatesByProducts = result._todasLasFechas.reduce((acc, producto) => {
                if (!acc[producto.id]) {
                    acc[producto.id] = [];
                }
                acc[producto.id] = producto.fechasDisponibles.fechas.map(f => f.fecha);
                return acc;
            }, {});

            if (this.enabledDates.length) {
                // const calendar = this.$refs['calendario'];
                // calendar.focusDate( this.enabledDates[0] );
                this.minDate = this.enabledDates[0];
                this.visitaFirstDate = true;

            }
            if ($vm.$route.query.cancel) {
                $vm.reservaCancelada = true;
            }

            this.dataReady = true;
        } catch (err) {
            console.log(err);
        } finally {
            this.movingToCart = false;
            this.loading = false;
            // this.cargandoDisponibilidad = false;
        }
    }
}
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* -webkit-appearance: none; */
    margin: 0;
}

/* Firefox */
/* input[type=number] {
  -moz-appearance: textfield;
  } */

.horario-selected {
    background-color: rgba(204, 255, 255, 0.5) !important;
    color: var(--spainhn-rojo-spainhn) !important;
}

.center {
    /* position: fixed; */
    position: relative;
    /* top: 50%; */
    /* bottom: 0; */
    /* left: 50%; */
    /* right: 0; */
    /* margin: auto; */
    transform: translate(-50%, -50%);
    z-index: 10;
}

.collapsable-header {
    border-radius: 10px;
    background: #D9D9D9;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 15px;
    padding: 15px;
    /* max-width: 520px; */
}

.header-title-container {
    display: flex;
    height: 55px;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.header-typhograpy {
    color: #FFF;
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.header-active-typhograpy {
    color: var(--spainhn-buy-button);
    font-family: 'Montserrat';
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: start;
}

.finish-button {
    display: flex;
    padding: 5px 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    border-radius: 20px;
    background: #850B0B !important;
}

.finish-text {
    display: flex;
    width: 290px;
    height: 38px;
    flex-direction: column;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
</style>

<style>
.reservasV2-date-picker .vc-day-content[data-v-4420d078]:hover {
    background-color: var(--spainhn-rojo-grisaceo) !important;
    color: #fff;
}

.loader-component {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>