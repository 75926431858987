var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("mdb-container", { staticClass: "flex flex-column px-0 my-2" }, [
    _c("div", { staticClass: "collapsable-header mx-auto" }, [
      _c(
        "div",
        { staticClass: "header-title-container header-active-typhograpy" },
        [
          _c("span", { staticClass: "text-uppercase" }, [
            _vm._v(" " + _vm._s(_vm.detallesDeLaSeleccion) + " "),
          ]),
          _c(
            "mdb-btn",
            {
              staticClass: "red darken-4",
              class: { "d-none": !_vm.horarioSelected },
              attrs: { floating: "", role: "button", color: "red" },
              on: {
                click: () =>
                  (_vm.mostrarOpcionesManual = !_vm.mostrarOpcionesManual),
              },
            },
            [_c("font-awesome-icon", { attrs: { icon: "fa-pen" } })],
            1
          ),
          _vm._t("detalles"),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "animated fadeIn",
          class: { "d-none": !_vm.mostrarOpciones },
          staticStyle: { width: "100%", "margin-top": "0" },
        },
        [
          _c("hr", { staticStyle: { color: "black", "margin-top": "0" } }),
          _c("div", { staticStyle: { "text-align": "start !important" } }, [
            _c(
              "div",
              { staticClass: "d-flex flex-wrap" },
              [
                _vm.opcionesDeHorarios.length
                  ? _vm._l(_vm.opcionesDeHorarios, function (horario, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass: "d-flex py-2 my-2 mx-md-2 mx-auto",
                          class: { "d-none": horario.hora === null },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "text-start py-2 px-4" },
                            [
                              _c(
                                "mdb-btn",
                                {
                                  staticClass: "spainhn-button my-auto",
                                  class: {
                                    "fa-bounce": _vm.horarioSelected === null,
                                    "active-item-option":
                                      horario._id === _vm.horarioSelected,
                                    "item-option":
                                      horario._id !== _vm.horarioSelected,
                                  },
                                  attrs: {
                                    size: "lg",
                                    disabled:
                                      horario.pausado === true ||
                                      horario._id === _vm.horarioSelected,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.setTarifa(horario)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    class: {
                                      "fa-shake": _vm.horarioSelected === null,
                                    },
                                    attrs: { icon: "fa-clock", size: "1x" },
                                  }),
                                  _vm._v(" " + _vm._s(horario.label) + " "),
                                ],
                                1
                              ),
                              _c(
                                "small",
                                {
                                  staticClass:
                                    "spainhn-text my-auto ms-2 w-100",
                                },
                                [
                                  _c(
                                    "span",
                                    { class: { "d-none": !_vm.mostrarAforo } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t("default.MENSAJE_AFORO")
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("strong", {
                                    class: { "d-none": !_vm.mostrarPlazas },
                                    domProps: {
                                      innerHTML: _vm._s(
                                        _vm.$t("default.MENSAJE_AFORO2", {
                                          plazas: `<span class='text-danger'>${horario.plazasDisponibles}</span>`,
                                        })
                                      ),
                                    },
                                  }),
                                  _c("span", [
                                    _vm._v(" " + _vm._s(horario.notaDeVenta)),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]
                      )
                    })
                  : _vm._e(),
              ],
              2
            ),
            _c(
              "div",
              {
                class: {
                  "d-none":
                    _vm.opcionesDeHorarios.length > 0 ||
                    _vm.loadingSchedule == true,
                },
              },
              [
                _c("p", { staticClass: "text-danger" }, [
                  _vm._v(_vm._s(_vm.$t("default.RESERVATION_STEP_2_2"))),
                ]),
              ]
            ),
            _c(
              "p",
              {
                staticClass: "text-start",
                class: { "d-none": !_vm.horarioLibre },
              },
              [
                _c("small", { staticClass: "spainhn-small-text" }, [
                  _vm._v(_vm._s(_vm.$t("default.CART_STEP1_NOTES_1")) + " "),
                  _c("br"),
                  _vm._v(
                    " (" + _vm._s(_vm.$t("default.CART_STEP1_NOTES_2")) + ") "
                  ),
                ]),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }